import { Checkbox, CheckboxProps } from "@faxi/web-component-library";
import { FieldProps, useFieldError } from "@faxi/web-form";
import { FC } from "react";

import { StyledCheckboxField } from "./CheckboxField.styled";

export type CheckboxFieldProps = FieldProps<boolean> &
  Omit<CheckboxProps, "ref">;

const CheckboxField: FC<CheckboxFieldProps> = (props): JSX.Element => {
  const { value, onChange, error, label, touched, dirty, ...rest } = props;

  const showError = useFieldError(props);

  return (
    <StyledCheckboxField>
      <Checkbox
        {...rest}
        label={label}
        aria-label={label}
        checked={Boolean(value)}
        onChange={onChange}
      />
      {showError && <small>{error}</small>}
    </StyledCheckboxField>
  );
};

export default CheckboxField;

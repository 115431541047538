enum StorageKeys {
  FORM_STORAGE_KEY = "kintoFinancialForm",
  COMPLETED_STEPS_STORAGE_KEY = "kintoFinancialFormCompletedSteps",
  LOAN_AMOUNT_RESULT_STORAGE_KEY = "kintoFinancialFormLoanAmountResult",
  SAVE_DATA_RESPONSE_KEY = "kintoFinancialFormSaveDataResponse",
  VEHICLE_PLATE = "vehiclePlate",
}

enum YesNo {
  YES = "yes",
  NO = "no",
}

enum MaritalStatus {
  SINGLE = "single",
  SINGLE_WITH_CHILDREN = "single_with_children",
  LIVING_TOGETHER = "living_together",
  LIVING_TOGETHER_WITH_CHILDREN = "living_together_with_children",
}

enum LivingSituation {
  RENT = "rent",
  HOMEOWNERS = "homeowners",
  RESIDENTIAL = "residential",
}

enum FormSteps {
  STEP_1 = "1",
  STEP_2 = "2",
  STEP_2_1 = "3",
  STEP_3 = "4",
  STEP_4 = "5",
  STEP_4_1 = "6",
  STEP_4_2 = "7",
  STEP_5 = "8",
  RESULT = "9",
}

const getYesNoOptions = (t: (message: string) => string) => [
  { value: YesNo.YES, label: t("yes") },
  { value: YesNo.NO, label: t("no") },
];

const DEPENDENT_FIELDS = {
  maritalStatus: [
    "haveChildrenUnder18",
    "payForChildcare",
    "childcareCosts",
    "anyChildBenefits",
    "childcareAllowance",
  ],
  haveChildrenUnder18: [
    "numOfChildren",
    "payForChildcare",
    "childcareCosts",
    "anyChildBenefits",
    "childcareAllowance",
  ],
  payForChildcare: ["childcareCosts"],
  anyChildBenefits: ["childcareAllowance"],
  livingSituation: [
    "grossHousingCost",
    "doYouReceiveMonthlyRentAllowance",
    "rentalAllowance",
    "otherMonthlyCommitments",
    "additionalDebt",
  ],
  doYouPayMonthlyPaymentsTowardsResidency: ["grossHousingCost"],
  otherMonthlyCommitments: ["additionalDebt"],
  doYouReceiveMonthlyRentAllowance: ["rentalAllowance"],
};

const CALL_US_PHONE = {
  HREF: "tel:0347397951",
  TEXT: "0347-397951",
};

const MAIL_US_HREF = "mailto:Particulier@toyotafs.nl";

export {
  CALL_US_PHONE,
  DEPENDENT_FIELDS,
  FormSteps,
  LivingSituation,
  MAIL_US_HREF,
  MaritalStatus,
  StorageKeys,
  YesNo,
  getYesNoOptions,
};

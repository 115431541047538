import React from "react";

import { CALL_US_PHONE, MAIL_US_HREF } from "common";
import { BaseHeader, CallUs, EmailUs } from "components";

import { StorageKeys } from "common";
import { useAnalytics } from "providers";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { storageService } from "services";
import { LoanApprovalResponse, LoanApprovalSingleResponse } from "types";
import { prepareResultDisplayedTrackEvent } from "../../prepareResultDisplayedTrackEvent";
import { StyledLoanRejected } from "./LoanRejected.styled";

export type LoanRejectedProps = {};

const LOAN_REJECTED_DATA = {
  mailHref: MAIL_US_HREF,
  phoneText: CALL_US_PHONE.TEXT,
  phoneHref: CALL_US_PHONE.HREF,
};

const LoanRejected: React.FC<LoanRejectedProps> = () => {
  const analytics = useAnalytics();
  const { t } = useTranslation();

  const { askingAmount } = storageService.getItem<LoanApprovalResponse>(
    StorageKeys.LOAN_AMOUNT_RESULT_STORAGE_KEY,
  );

  useEffect(() => {
    if (askingAmount && analytics) {
      const handleResultDisplayedTrackEvent = (
        loanApprovalResponse: LoanApprovalSingleResponse,
      ) => {
        const eventBody =
          prepareResultDisplayedTrackEvent(loanApprovalResponse);
        analytics.track("Results Displayed", eventBody);
      };

      handleResultDisplayedTrackEvent(askingAmount);
    }
  }, [askingAmount, analytics]);

  return (
    <StyledLoanRejected className="fc-loan-rejected">
      <BaseHeader />

      <div className="fc-loan-rejected__card element-max-width">
        <h2>{t("outcome_declined_title")}</h2>
        <p>{t("outcome_declined_text")}</p>

        <EmailUs
          href={LOAN_REJECTED_DATA.mailHref}
          mailText={t("outcome_emailus")}
        />

        <p>{t("outcome_orcall")}</p>

        <CallUs
          href={LOAN_REJECTED_DATA.phoneHref}
          phoneText={LOAN_REJECTED_DATA.phoneText}
        />
      </div>
    </StyledLoanRejected>
  );
};

export default LoanRejected;

import { UserCredentials } from "./interfaces";

const validateUserCredentials = (data: any): data is UserCredentials => {
  return (
    typeof data === "object" &&
    data.id_token !== null &&
    data.access_token !== null &&
    data.refresh_token !== null &&
    data.expires_in !== null &&
    data.token_type !== null
  );
};

export { validateUserCredentials };

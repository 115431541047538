import styled from "styled-components";

import { VehicleStepHeader } from "components";

const StyledStepPage = styled(VehicleStepHeader)`
  .field-modal-label p {
    margin-bottom: 0;
  }

  legend {
    padding-left: 0;
  }
`;

export { StyledStepPage };

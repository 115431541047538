import {
  flex,
  fontSize,
  mobile,
  position,
  pxToRem,
} from "@faxi/web-css-utilities";
import styled, { css } from "styled-components";

const StyledBaseHeader = styled.div`
  ${position("absolute", "bottom auto left 0 right 0 top 0px")};
  background-color: var(--GRAY-EF);
  padding-bottom: ${pxToRem("20px")};
`;

const StyledText = styled.h1`
  ${flex("row", "center", "center")};

  padding-top: ${pxToRem("20px")};
  gap: ${pxToRem("8px")};
  margin: 0 auto ${pxToRem("20px")};

  ${fontSize("26px", "30px")};

  ${mobile(css`
    ${fontSize("21px", "24px")};
  `)}
`;

const StyledHr = styled.hr`
  background-color: var(--PRIMARY_1_1);
  border: none;
  height: 2px;

  width: 100%;
  max-width: 98px;
  margin-bottom: 0;
`;

export { StyledBaseHeader, StyledHr, StyledText };

import { theme } from "@faxi/web-component-library";
import { flex } from "@faxi/web-css-utilities";
import styled from "styled-components";

const StyledHeaderBranding = styled.div`
  background-color: white;

  .ffc-language-selector {
    max-width: ${theme.sizes.SIZE_80};
  }
`;

const Section = styled.div`
  ${flex("column")};
`;

const IconRowWrapper = styled.div`
  background: var(--GRAY-FB);
  border-bottom: 1px solid var(--GRAY-EF);
`;

const IconRow = styled.div`
  ${flex("row", "space-between", "center")};

  padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_16};
  margin: auto;
  max-width: 436px;
`;

const LeftIcon = styled.img`
  height: 12px;
`;

const RightIcon = styled.img`
  height: 24px;
`;

export {
  IconRow,
  IconRowWrapper,
  LeftIcon,
  RightIcon,
  Section,
  StyledHeaderBranding,
};

import React, { ReactNode } from "react";

import { StyledBaseCard } from "./BaseCard.styled";

export type BaseCardProps = {
  variant?: 0 | 1;
  className?: string;
  children?: ReactNode;
};

const BaseCard: React.FC<BaseCardProps> = ({
  className,
  children,
  variant = 0,
}) => (
  <StyledBaseCard variant={variant} className={className}>
    {children}
  </StyledBaseCard>
);

export default BaseCard;

import React from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import { HeaderBranding } from "components";
import { StyledBaseHeader, StyledHr, StyledText } from "./BaseHeader.styled";

type BaseHeaderProps = {
  className?: string;
  title?: string;
};

const HEADER_CONFIG = {
  IMAGE: {
    src: "/icons/man-right.svg",
    alt: "Header man right",
  },
} as const;

const BaseHeader: React.FC<BaseHeaderProps> = ({ title, className }) => {
  const { t } = useTranslation();
  const finalTitle = title ?? t("money_disclaimer");

  return (
    <StyledBaseHeader className={classNames("fc-base-header", className)}>
      <HeaderBranding />

      <StyledText>
        {finalTitle}

        <img
          loading="eager"
          src={HEADER_CONFIG.IMAGE.src}
          alt={HEADER_CONFIG.IMAGE.alt}
        />
      </StyledText>

      <StyledHr />
    </StyledBaseHeader>
  );
};

export default BaseHeader;

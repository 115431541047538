import { StorageKeys } from "common";
import { storageService } from "services";
import { IncomeType, LivingSituation, ManualInputs, Status } from "types";
import { convertToNumber } from "utils";

type FormResult = {
  maritalStatus: Status;
  livingSituation: LivingSituation;
  incomeType: IncomeType;
  incomeNet: string;
  rentalAllowance: string;
  childcareCosts: string;
  additionalDebt: string;
  grossHousingCost: string;
  loanAmount: string;
  cashDeposit: string;
  childcareAllowance: string;
  numOfChildren: string;
};

export const getFormResult = (hasCarInfo: boolean): Partial<ManualInputs> => {
  const formResult = storageService.getItem<FormResult>(
    StorageKeys.FORM_STORAGE_KEY,
  );

  if (!formResult) return {};

  const {
    maritalStatus,
    livingSituation,
    incomeNet,
    rentalAllowance,
    childcareCosts,
    additionalDebt,
    grossHousingCost,
    loanAmount,
    cashDeposit,
    incomeType,
    numOfChildren,
    childcareAllowance,
  } = formResult;

  const finalPrice = convertToNumber(loanAmount) + convertToNumber(cashDeposit);

  return {
    maritalStatus,
    livingSituation,
    incomeType,
    incomeNet: convertToNumber(incomeNet),
    grossHousingCost: convertToNumber(grossHousingCost),
    rentalAllowance: convertToNumber(rentalAllowance),
    additionalDebt: convertToNumber(additionalDebt),
    childcareCosts: convertToNumber(childcareCosts),
    childcareAllowance: convertToNumber(childcareAllowance),
    numOfChildren: convertToNumber(numOfChildren),
    deposit: convertToNumber(cashDeposit),
    ...(hasCarInfo ? { carPrice: finalPrice } : { askingAmount: finalPrice }),
  };
};

import { storageService } from "services";
import {
  CognitoConfig,
  CognitoConfigParameters,
  UserCredentialsState,
} from "./interfaces";
import env from "env";
import { getAppUrl, getAuthUrl } from "providers/KFQueryContext/utils/url";
import { APP_URI } from "common";

const defaultValue = "";

const defaultCognitoConfig: CognitoConfig = {
  grant_type: "authorization_code",
  loginPath: "login",
  logoutPath: "logout",
  response_type: "code",
  scope: "aws.cognito.signin.user.admin+email+openid+phone+profile",
  customAuthDomain: defaultValue,
  clientId: defaultValue,
  redirectUri: defaultValue,
};

const setConfig = (
  configParameters: CognitoConfigParameters,
): CognitoConfig => ({
  ...defaultCognitoConfig,
  ...configParameters,
});

const getRedirectUrls = (config: CognitoConfig) => {
  const {
    customAuthDomain,
    loginPath,
    logoutPath,
    scope,
    clientId,
    response_type,
    redirectUri,
  } = config;

  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("client_id", clientId);
  urlSearchParams.append("response_type", response_type);
  urlSearchParams.append("redirect_uri", redirectUri);

  const loginRedirectUrl = `https://${customAuthDomain}/${loginPath}?scope=${scope}&${urlSearchParams.toString()}`;
  const logoutRedirectUrl = `https://${customAuthDomain}/${logoutPath}?scope=${scope}&${urlSearchParams.toString()}`;

  return {
    loginRedirectUrl,
    logoutRedirectUrl,
  };
};

type UserCredentialsRequestBody = {
  grant_type?: string;
  client_id: string;
  code?: string;
  redirect_uri?: string;
};

type GetUserCredentialsData = {
  body: string;
  getUserCredentialsUrl: string;
};

const getUserCredentialsData =
  (config: CognitoConfig) =>
  (code?: string): GetUserCredentialsData => {
    const { customAuthDomain, grant_type, clientId, redirectUri } = config;
    const refreshToken =
      storageService.getItem<UserCredentialsState>(
        "userCredentials",
      )?.refresh_token;

    const data: UserCredentialsRequestBody = {
      client_id: clientId,
      redirect_uri: redirectUri,
      ...(code
        ? { code, grant_type }
        : { refresh_token: refreshToken, grant_type: "refresh_token" }),
    };

    function encodeFormData(data: Record<string, any>) {
      return Object.keys(data)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(data[key]),
        )
        .join("&");
    }

    const body = encodeFormData(data);
    const getUserCredentialsUrl = `https://${customAuthDomain}/oauth2/token`;

    return {
      body,
      getUserCredentialsUrl,
    };
  };

const getConfig = () => {
  const customAuthDomain = env.VITE_CUSTOM_AUTH_DOMAIN || getAuthUrl();
  const clientId = env.VITE_AUTH_CLIENT_ID;
  const redirectUri = `${getAppUrl()}${APP_URI.CREDENTIALS}`;

  return setConfig({
    customAuthDomain,
    clientId,
    redirectUri,
  });
};

export { setConfig, getRedirectUrls, getUserCredentialsData, getConfig };

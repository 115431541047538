import React, { useEffect } from "react";
import { useAuthenticateUser } from "./AuthenticateUserProvider";

const LoginRedirect: React.FC<{ locationPath: string }> = ({
  locationPath,
}) => {
  const { loginRedirectUrl, setFromLocation } = useAuthenticateUser();

  useEffect(() => {
    locationPath && setFromLocation(locationPath);
    window.location.href = loginRedirectUrl;
  }, [locationPath, loginRedirectUrl, setFromLocation]);

  return <></>;
};

export default LoginRedirect;

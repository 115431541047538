const getRootDomain = () => {
  const host = window.location.host;
  return host.replace("app.", "");
};

const addSubdomain = (subdomain: string) => (domain: string) =>
  `https://${subdomain}.${domain}`;
const addApiSubdomain = addSubdomain("api");
// const addAuthSubdomain = addSubdomain("auth");

const getAppUrl = () => {
  const host = window.location.host;
  return host.includes("localhost") ? `http://${host}` : `https://${host}`;
};

const getApiUrl = () => {
  const rootDomain = getRootDomain();
  const apiUrl = addApiSubdomain(rootDomain);
  return `${apiUrl}/v1`;
};

const getAuthUrl = () => {
  const host = window.location.host;
  return `auth.${host}`;
};

export { getApiUrl, getAuthUrl, getAppUrl };

import { theme } from "@faxi/web-component-library";
import styled from "styled-components";

import { Header } from "components";

const StyledCalculator = styled(Header)`
  form {
    max-width: 800px;
  }

  th,
  td {
    p {
      min-height: 22px;
    }
  }

  th:last-of-type {
    text-align: end;
  }

  td {
    p {
      padding: ${theme.sizes.SIZE_12} 0;
    }

    input {
      min-width: ${theme.sizes.SIZE_80};
      padding: ${theme.sizes.SIZE_8};
    }
  }
`;

export { StyledCalculator };

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { defaultMutationFn, defaultQueryFn } from "./utils";

const isProductionEnv = import.meta.env.PROD;

const DEFAULT_STALE_TIME = 5 * 60 * 1000; // milliseconds

export type KFQueryClientProviderProps = { children: React.ReactElement };

const client = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      staleTime: DEFAULT_STALE_TIME,
      retry: 1,
    },
    mutations: {
      mutationFn: defaultMutationFn,
    },
  },
});

const KFQueryClientProvider: React.FC<KFQueryClientProviderProps> = ({
  children,
}) => (
  <QueryClientProvider client={client}>
    {children}
    {!isProductionEnv && <ReactQueryDevtools />}
  </QueryClientProvider>
);

export { KFQueryClientProvider };

import { DEPENDENT_FIELDS, YesNo } from "common";
import { clearFormFields } from "utils";

export const onHaveChildrenUnder18Change = (haveChildrenUnder18: string) => {
  if (haveChildrenUnder18 === YesNo.NO)
    clearFormFields(DEPENDENT_FIELDS.haveChildrenUnder18);
};

export const onPayForChildcareChange = (payForChildcare: string) => {
  if (payForChildcare === YesNo.NO)
    clearFormFields(DEPENDENT_FIELDS.payForChildcare);
};

export const onAnyChildBenefitsChange = (anyChildBenefits: string) => {
  if (anyChildBenefits === YesNo.NO)
    clearFormFields(DEPENDENT_FIELDS.anyChildBenefits);
};

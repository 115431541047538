import { Button, useCallbackRef } from "@faxi/web-component-library";
import { FormField, FormRef, validators } from "@faxi/web-form";
import { useMutation } from "@tanstack/react-query";
import parse from "html-react-parser";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { API_PATHS, APP_URI, FormSteps, StorageKeys } from "common";
import {
  CheckboxField,
  CircularSpinner,
  FormActions,
  FormStep,
  InputField,
} from "components";
import { storageService } from "services";
import {
  LoanAmountStatus,
  LoanApprovalResponse,
  MutationMethod,
  MutationVariables,
  SaveDataResponse,
} from "types";
import { getFormResult, localValidators } from "utils";

import { useAnalytics, useVehicle } from "providers";
import { PersonalDetails, getPersonalDetails } from "utils/getPersonalDetails";
import { StyledStep5PersonalDetails } from "./Step5PersonalDetails.styled";

export type Step5PersonalDetailsFormValue = {};
export type Step5PersonalDetailsProps = {};

const MAX_PHONE_CHARACTERS = 10;

const Step5PersonalDetails: React.FC<Step5PersonalDetailsProps> = () => {
  const [form, formRef] = useCallbackRef<FormRef>();
  const history = useHistory();
  const analytics = useAnalytics();
  const { data: vehicle } = useVehicle();

  const { t } = useTranslation();

  const handleSubmittedTrackEvent = useCallback(
    ({ referenceNumber }: SaveDataResponse) => {
      analytics.track("Submitted", {
        reference: referenceNumber ?? "",
      });
    },
    [analytics],
  );

  const handleIdentifyEvent = useCallback(
    ({
      email,
      firstName,
      lastName,
      personId,
      telephone,
      referenceNumber,
    }: PersonalDetails & { personId: string; referenceNumber?: string }) => {
      analytics.identify(personId, {
        firstName,
        lastName,
        email,
        phone: telephone,
        lastReferenceId: referenceNumber ?? "",
      });
    },
    [analytics],
  );

  const vehiclePlate = vehicle?.licencePlate;

  const { mutate: saveDataMutation, isPending } = useMutation<
    SaveDataResponse,
    Error,
    MutationVariables
  >({
    onSuccess: (data) => {
      storageService.setItem(StorageKeys.SAVE_DATA_RESPONSE_KEY, data);
      const personalDetails = getPersonalDetails() as PersonalDetails;

      handleSubmittedTrackEvent(data);
      handleIdentifyEvent({ ...personalDetails, ...data });

      history.push(APP_URI.RESULT);
    },
    onError: (err) => {
      storageService.setItem(StorageKeys.SAVE_DATA_RESPONSE_KEY, {
        status: LoanAmountStatus.ERROR,
      });

      const defaultErrorMessage = t("error_cannot_submit_form");

      alert(err.message || defaultErrorMessage);
    },
  });

  const {
    mutate: mutateLoanApproval,
    isPending: isPendingLoanApprovalMutation,
  } = useMutation<LoanApprovalResponse, Error, MutationVariables>({
    onSuccess: (loanCalculationResult) => {
      const { askingAmount } = loanCalculationResult;

      storageService.setItem(
        StorageKeys.LOAN_AMOUNT_RESULT_STORAGE_KEY,
        loanCalculationResult,
      );

      const loanCalculationRequest = getFormResult(!!vehiclePlate);
      const personalDetails = getPersonalDetails();

      saveDataMutation({
        path: API_PATHS.SAVE_DATA,
        method: MutationMethod.POST,
        body: {
          loanCalculationRequest,
          loanCalculationResult: askingAmount,
          personalDetails,
          licencePlate: vehiclePlate || null,
        },
      });
    },
    onError: (err) => {
      storageService.setItem(StorageKeys.LOAN_AMOUNT_RESULT_STORAGE_KEY, {
        status: LoanAmountStatus.ERROR,
      });

      const defaultErrorMessage = t("error_cannot_submit_form");

      alert(err.message || defaultErrorMessage);
    },
  });

  const handleSubmit = useCallback(async () => {
    storageService.setItem(
      StorageKeys.COMPLETED_STEPS_STORAGE_KEY,
      FormSteps.STEP_5,
    );
    const loanCalculationRequest = getFormResult(!!vehiclePlate);

    mutateLoanApproval({
      path: API_PATHS.LOAN_APPROVAL,
      method: MutationMethod.POST,
      body: loanCalculationRequest,
    });
  }, [mutateLoanApproval, vehiclePlate]);

  const validations = useMemo(
    () => ({
      firstName: [
        validators.general.required(t("error_required_field")),
        validators.general.regex(
          localValidators.user.first_name,
          t("error_invalid_name"),
        ),
      ],
      lastName: [
        validators.general.required(t("error_required_field")),
        validators.general.regex(
          localValidators.user.last_name,
          t("error_invalid_name"),
        ),
      ],
      email: [
        validators.general.required(t("error_required_field")),
        validators.general.regex(
          localValidators.user.email,
          t("error_invalid_email"),
        ),
      ],
      phone: [
        validators.general.required(t("error_required_field")),
        validators.general.maxLength(
          MAX_PHONE_CHARACTERS,
          t("error_max_length", { MAX_PHONE_CHARACTERS }),
        ),
        validators.phone.regex(
          localValidators.user.phone,
          t("error_invalid_phone"),
        ),
      ],
      confirmContact: [
        localValidators.user.confirm_contact(t("error_confirm_contact")),
      ],
    }),
    [t],
  );

  return (
    <StyledStep5PersonalDetails className="fc-step-5">
      <FormStep
        className="fc-step-5__form"
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <FormField
          name="firstName"
          label={t("details_name")}
          required
          validate={validations.firstName}
          component={InputField}
        />

        <FormField
          name="lastName"
          label={t("details_surname")}
          required
          validate={validations.lastName}
          component={InputField}
        />

        <FormField
          name="email"
          label={t("details_email")}
          required
          validate={validations.email}
          component={InputField}
        />

        <FormField
          name="phoneNumber"
          label={t("details_telephone")}
          required
          validate={validations.phone}
          component={InputField}
          inputMode="numeric"
        />

        <div className="fc-step-5__confirm-contact">
          <FormField
            name="agreement"
            label={t("details_disclaimer1")}
            component={CheckboxField}
            validate={validations.confirmContact}
          />
        </div>

        <p className="fc-step-5__privacy-statement">
          {parse(
            t("details_disclaimer2", {
              PRIVACY_STATEMENT: `<a href="${APP_URI.PRIVACY_POLICY}">${t("footer_privacy_link")}</a>`,
            }),
          )}
        </p>

        <FormActions hasBackButton>
          <Button
            type="submit"
            disabled={
              !form?.syncFormValid || isPendingLoanApprovalMutation || isPending
            }
          >
            {isPendingLoanApprovalMutation || isPending ? (
              <CircularSpinner />
            ) : (
              t("next")
            )}
          </Button>
        </FormActions>
      </FormStep>
    </StyledStep5PersonalDetails>
  );
};

export default Step5PersonalDetails;

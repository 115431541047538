import { convertToNumber } from "utils";
import { ParametersTable } from "../types";

export const parseTableData = (data: any): ParametersTable => ({
  single: {
    netIncome: {
      modal: convertToNumber(data.single_netIncomeModal),
      modal1_5x: convertToNumber(data.single_netIncomeModal1_5x),
    },
    surplusHomeOwnership: {
      perc50: convertToNumber(data.single_surplusHomeOwnershipPerc50),
      perc100: convertToNumber(data.single_surplusHomeOwnershipPerc100),
      perc150: convertToNumber(data.single_surplusHomeOwnershipPerc150),
    },
    basicStandard: {
      rent: convertToNumber(data.single_basicStandardRent),
      homeowners: convertToNumber(data.single_basicStandardHomeowners),
      residential: convertToNumber(data.single_basicStandardResidental),
    },
    surplusCarOwnership: {
      perc50: convertToNumber(data.single_surplusCarOwnershipPerc50),
      perc100: convertToNumber(data.single_surplusCarOwnershipPerc100),
      perc150: convertToNumber(data.single_surplusCarOwnershipPerc150),
    },
    maxBorrowing: {
      rent: convertToNumber(data.single_maxBorrowingRent),
      homeowners: convertToNumber(data.single_maxBorrowingHomeowners),
      residential: convertToNumber(data.single_maxBorrowingResidental),
    },
    housingCostDiscount: {
      perc50: convertToNumber(data.single_housingCostDiscountPerc50),
      perc100: convertToNumber(data.single_housingCostDiscountPerc100),
      perc150: convertToNumber(data.single_housingCostDiscountPerc150),
    },
    rate: convertToNumber(data.single_rate),
    incomeType: {
      employed_under_contract: convertToNumber(
        data.single_incomeTypeEmployedUnderContract,
      ),
      pension_aow: convertToNumber(data.single_incomeTypePensionAow),
      social_security: convertToNumber(data.single_incomeTypeSocialSecurity),
      other: convertToNumber(data.single_incomeTypeOther),
    },
    minimumIncome: convertToNumber(data.single_minimumIncome),
  },
  couple: {
    netIncome: {
      modal: convertToNumber(data.couple_netIncomeModal),
      modal1_5x: convertToNumber(data.couple_netIncomeModal1_5x),
    },
    surplusHomeOwnership: {
      perc50: convertToNumber(data.couple_surplusHomeOwnershipPerc50),
      perc100: convertToNumber(data.couple_surplusHomeOwnershipPerc100),
      perc150: convertToNumber(data.couple_surplusHomeOwnershipPerc150),
    },
    basicStandard: {
      rent: convertToNumber(data.couple_basicStandardRent),
      homeowners: convertToNumber(data.couple_basicStandardHomeowners),
      residential: convertToNumber(data.couple_basicStandardResidental),
    },
    surplusCarOwnership: {
      perc50: convertToNumber(data.couple_surplusCarOwnershipPerc50),
      perc100: convertToNumber(data.couple_surplusCarOwnershipPerc100),
      perc150: convertToNumber(data.couple_surplusCarOwnershipPerc150),
    },
    maxBorrowing: {
      rent: convertToNumber(data.couple_maxBorrowingRent),
      homeowners: convertToNumber(data.couple_maxBorrowingHomeowners),
      residential: convertToNumber(data.couple_maxBorrowingResidental),
    },
    housingCostDiscount: {
      perc50: convertToNumber(data.couple_housingCostDiscountPerc50),
      perc100: convertToNumber(data.couple_housingCostDiscountPerc100),
      perc150: convertToNumber(data.couple_housingCostDiscountPerc150),
    },
    rate: convertToNumber(data.couple_rate),
    incomeType: {
      employed_under_contract: convertToNumber(
        data.couple_incomeTypeEmployedUnderContract,
      ),
      pension_aow: convertToNumber(data.couple_incomeTypePensionAow),
      social_security: convertToNumber(data.couple_incomeTypeSocialSecurity),
      other: convertToNumber(data.couple_incomeTypeOther),
    },
    minimumIncome: convertToNumber(data.couple_minimumIncome),
  },
  singleWithChildren: {
    netIncome: {
      modal: convertToNumber(data.singleWithChildren_netIncomeModal),
      modal1_5x: convertToNumber(data.singleWithChildren_netIncomeModal1_5x),
    },
    surplusHomeOwnership: {
      perc50: convertToNumber(
        data.singleWithChildren_surplusHomeOwnershipPerc50,
      ),
      perc100: convertToNumber(
        data.singleWithChildren_surplusHomeOwnershipPerc100,
      ),
      perc150: convertToNumber(
        data.singleWithChildren_surplusHomeOwnershipPerc150,
      ),
    },
    basicStandard: {
      rent: convertToNumber(data.singleWithChildren_basicStandardRent),
      homeowners: convertToNumber(
        data.singleWithChildren_basicStandardHomeowners,
      ),
      residential: convertToNumber(
        data.singleWithChildren_basicStandardResidental,
      ),
    },
    surplusCarOwnership: {
      perc50: convertToNumber(
        data.singleWithChildren_surplusCarOwnershipPerc50,
      ),
      perc100: convertToNumber(
        data.singleWithChildren_surplusCarOwnershipPerc100,
      ),
      perc150: convertToNumber(
        data.singleWithChildren_surplusCarOwnershipPerc150,
      ),
    },
    maxBorrowing: {
      rent: convertToNumber(data.singleWithChildren_maxBorrowingRent),
      homeowners: convertToNumber(
        data.singleWithChildren_maxBorrowingHomeowners,
      ),
      residential: convertToNumber(
        data.singleWithChildren_maxBorrowingResidental,
      ),
    },
    housingCostDiscount: {
      perc50: convertToNumber(
        data.singleWithChildren_housingCostDiscountPerc50,
      ),
      perc100: convertToNumber(
        data.singleWithChildren_housingCostDiscountPerc100,
      ),
      perc150: convertToNumber(
        data.singleWithChildren_housingCostDiscountPerc150,
      ),
    },
    rate: convertToNumber(data.singleWithChildren_rate),
    incomeType: {
      employed_under_contract: convertToNumber(
        data.singleWithChildren_incomeTypeEmployedUnderContract,
      ),
      pension_aow: convertToNumber(
        data.singleWithChildren_incomeTypePensionAow,
      ),
      social_security: convertToNumber(
        data.singleWithChildren_incomeTypeSocialSecurity,
      ),
      other: convertToNumber(data.singleWithChildren_incomeTypeOther),
    },
    minimumIncome: convertToNumber(data.singleWithChildren_minimumIncome),
    childrenBenefitFactor: convertToNumber(data.childrenBenefitFactor),
  },
  coupleWithChildren: {
    netIncome: {
      modal: convertToNumber(data.coupleWithChildren_netIncomeModal),
      modal1_5x: convertToNumber(data.coupleWithChildren_netIncomeModal1_5x),
    },
    surplusHomeOwnership: {
      perc50: convertToNumber(
        data.coupleWithChildren_surplusHomeOwnershipPerc50,
      ),
      perc100: convertToNumber(
        data.coupleWithChildren_surplusHomeOwnershipPerc100,
      ),
      perc150: convertToNumber(
        data.coupleWithChildren_surplusHomeOwnershipPerc150,
      ),
    },
    basicStandard: {
      rent: convertToNumber(data.coupleWithChildren_basicStandardRent),
      homeowners: convertToNumber(
        data.coupleWithChildren_basicStandardHomeowners,
      ),
      residential: convertToNumber(
        data.coupleWithChildren_basicStandardResidental,
      ),
    },
    surplusCarOwnership: {
      perc50: convertToNumber(
        data.coupleWithChildren_surplusCarOwnershipPerc50,
      ),
      perc100: convertToNumber(
        data.coupleWithChildren_surplusCarOwnershipPerc100,
      ),
      perc150: convertToNumber(
        data.coupleWithChildren_surplusCarOwnershipPerc150,
      ),
    },
    maxBorrowing: {
      rent: convertToNumber(data.coupleWithChildren_maxBorrowingRent),
      homeowners: convertToNumber(
        data.coupleWithChildren_maxBorrowingHomeowners,
      ),
      residential: convertToNumber(
        data.coupleWithChildren_maxBorrowingResidental,
      ),
    },
    housingCostDiscount: {
      perc50: convertToNumber(
        data.coupleWithChildren_housingCostDiscountPerc50,
      ),
      perc100: convertToNumber(
        data.coupleWithChildren_housingCostDiscountPerc100,
      ),
      perc150: convertToNumber(
        data.coupleWithChildren_housingCostDiscountPerc150,
      ),
    },
    rate: convertToNumber(data.coupleWithChildren_rate),
    incomeType: {
      employed_under_contract: convertToNumber(
        data.coupleWithChildren_incomeTypeEmployedUnderContract,
      ),
      pension_aow: convertToNumber(
        data.coupleWithChildren_incomeTypePensionAow,
      ),
      social_security: convertToNumber(
        data.coupleWithChildren_incomeTypeSocialSecurity,
      ),
      other: convertToNumber(data.coupleWithChildren_incomeTypeOther),
    },
    minimumIncome: convertToNumber(data.coupleWithChildren_minimumIncome),
    childrenBenefitFactor: convertToNumber(data.childrenBenefitFactor),
  },
});

import { StepsProvider } from "@faxi/web-form";
import React, { useCallback } from "react";
import { renderRoutes } from "react-router-config";
import { Route, useHistory } from "react-router-dom";

import { APP_URI, StorageKeys } from "common";
import { useVehicle } from "providers";

import { FORM_STEPS } from "./steps/routes";
import { MAX_LOAN_AMOUNT } from "./steps/Step1/Step1.component";

export declare type Step = {
  handle: string;
  path: string;
  exact: boolean;
  component: React.ComponentType;
};

const FormWizard: React.FC<{ route?: Route }> = () => {
  const history = useHistory();
  const { data: vehicle } = useVehicle();

  const handleSubmit = useCallback(() => {
    history.push(APP_URI.RESULT);
  }, [history]);

  const carPrice = vehicle?.price || 0;

  const defaultLoanAmount = Math.min(carPrice, MAX_LOAN_AMOUNT);

  const defaultDeposit =
    carPrice && carPrice > MAX_LOAN_AMOUNT ? carPrice - MAX_LOAN_AMOUNT : 0;

  return (
    <StepsProvider
      defaultData={{
        loanAmount: defaultLoanAmount,
        cashDeposit: defaultDeposit,
        grossHousingCost: "0",
      }}
      formStepsCompletedStorageKey={StorageKeys.COMPLETED_STEPS_STORAGE_KEY}
      steps={FORM_STEPS}
      formStorageKey={StorageKeys.FORM_STORAGE_KEY}
      onStepsCompleted={handleSubmit}
    >
      {renderRoutes(FORM_STEPS)}
    </StepsProvider>
  );
};

export default FormWizard;

import { createBrowserHistory } from "history";
import React from "react";
import { renderRoutes } from "react-router-config";
import { Router as ReactRouter, Redirect } from "react-router-dom";

import { APP_URI } from "common";
import { BASE_INQUIRY_PATH } from "common/routes";
import { VehicleData } from "providers";
import FormWizard from "./FormWizard.page";
import { AdminRouter } from "./admin";
import { CookiesPolicy, PrivacyPolicy, TermsOfService } from "./inquiry";
import { Home, Result } from "./steps";

const history = createBrowserHistory({ basename: "/" });

const mainRoutes = [
  {
    handle: "base",
    path: "/",
    exact: true,
    render: () => <Redirect to={APP_URI.HOME} />,
  },
  {
    handle: "home",
    path: APP_URI.HOME,
    exact: true,
    component: () => (
      <React.Suspense fallback={<div />}>
        <Home />
      </React.Suspense>
    ),
  },
  {
    handle: "result",
    path: APP_URI.RESULT,
    exact: true,
    component: Result,
  },
  {
    handle: "privacy-policy",
    path: APP_URI.PRIVACY_POLICY,
    exact: true,
    component: PrivacyPolicy,
  },
  {
    handle: "cookies-policy",
    path: APP_URI.COOKIES_POLICY,
    exact: true,
    component: CookiesPolicy,
  },
  {
    handle: "terms-of-service",
    path: APP_URI.TERMS_OF_SERVICE,
    exact: true,
    component: TermsOfService,
  },
  {
    handle: "form",
    path: `${BASE_INQUIRY_PATH}/*`,
    component: FormWizard,
  },
];

const Router: React.FC = (): JSX.Element => (
  <ReactRouter history={history}>
    <AdminRouter />
    <VehicleData>{renderRoutes(mainRoutes)}</VehicleData>
  </ReactRouter>
);

export default Router;

import { flex, position, pxToRem, size } from "@faxi/web-css-utilities";
import styled from "styled-components";

const Loader = styled.div`
  ${size("100%")};
  ${position("absolute", "0")};
  ${flex("column", "center", "center")};

  z-index: 999;
  background-color: var(--WHITE);
  backdrop-filter: blur(1px);
`;

const LoaderLottie = styled.div`
  transform: translateY(-24px) scale(0.65);
`;

const StyledRedirectingText = styled.p`
  color: var(--TEXT_SECONDARY);
`;

const StyledRedirectingFooter = styled.footer`
  ${position("absolute", "bottom 0 top auto left 0 right 0")};
  ${flex("column", "center", "center")}
  gap: ${pxToRem("32px")};

  padding: ${pxToRem("56px")};
  background-color: var(--GRAY-FB);

  > strong {
    font-size: ${pxToRem("15px")};
  }

  > img {
    width: 209px;
  }
`;

export { Loader, LoaderLottie, StyledRedirectingFooter, StyledRedirectingText };

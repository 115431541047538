import { validationRegexes } from "@faxi/web-form";
import XRegExp from "xregexp";

export const NAME_REGEX = XRegExp("^(?=.*[\\p{L}])([\\p{L} -]{2,})$");
export const PHONE_REGEX = XRegExp("^0\\d{9}$");

const user = {
  first_name: NAME_REGEX,
  last_name: NAME_REGEX,
  email: validationRegexes.workEmail,
  phone: PHONE_REGEX,
  confirm_contact: (errorMessage: string) => (value: boolean) =>
    value ? "" : errorMessage,
};

export default {
  user,
};

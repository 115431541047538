import { theme } from "@faxi/web-component-library";
import { flex } from "@faxi/web-css-utilities";
import styled from "styled-components";

const StyledOptionModalLabel = styled.div`
  > span {
    ${flex("row", "flex-start", "center")};
    gap: ${theme.sizes.SIZE_8};
    width: fit-content;

    > img {
      cursor: pointer;
    }
  }
`;

export { StyledOptionModalLabel };

import { AnalyticsBrowser } from "@segment/analytics-next";

class Analytics {
  private static instance: Analytics;
  private analytics: AnalyticsBrowser;

  private constructor() {
    this.analytics = AnalyticsBrowser.load({
      writeKey: "JCfUV5H2mkRYjdYV8LrsSzAwHymp5iwL",
    });
  }

  public static getInstance(): AnalyticsBrowser {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }

    return Analytics.instance.analytics;
  }
}

export default Analytics;

import { ParametersTable } from "../types";

export const generateTableData = (data: ParametersTable) => ({
  single_netIncomeModal: data.single.netIncome.modal,
  single_surplusHomeOwnershipPerc50: data.single.surplusHomeOwnership.perc50,
  single_basicStandardRent: data.single.basicStandard.rent,
  single_surplusCarOwnershipPerc50: data.single.surplusCarOwnership.perc50,
  single_maxBorrowingRent: data.single.maxBorrowing.rent,
  single_housingCostDiscountPerc50: data.single.housingCostDiscount.perc50,
  single_netIncomeModal1_5x: data.single.netIncome.modal1_5x,
  single_surplusHomeOwnershipPerc100: data.single.surplusHomeOwnership.perc100,
  single_basicStandardHomeowners: data.single.basicStandard.homeowners,
  single_surplusCarOwnershipPerc100: data.single.surplusCarOwnership.perc100,
  single_maxBorrowingHomeowners: data.single.maxBorrowing.homeowners,
  single_housingCostDiscountPerc100: data.single.housingCostDiscount.perc100,
  single_rate: data.single.rate,
  single_minimumIncome: data.single.minimumIncome,
  single_surplusHomeOwnershipPerc150: data.single.surplusHomeOwnership.perc150,
  single_basicStandardResidental: data.single.basicStandard.homeowners,
  single_surplusCarOwnershipPerc150: data.single.surplusCarOwnership.perc150,
  single_maxBorrowingResidental: data.single.maxBorrowing.homeowners,
  single_housingCostDiscountPerc150: data.single.housingCostDiscount.perc150,
  single_incomeTypeEmployedUnderContract:
    data.single.incomeType.employed_under_contract,
  single_incomeTypePensionAow: data.single.incomeType.pension_aow,
  single_incomeTypeSocialSecurity: data.single.incomeType.social_security,
  single_incomeTypeOther: data.single.incomeType.other,

  couple_netIncomeModal: data.couple.netIncome.modal,
  couple_surplusHomeOwnershipPerc50: data.couple.surplusHomeOwnership.perc50,
  couple_basicStandardRent: data.couple.basicStandard.rent,
  couple_surplusCarOwnershipPerc50: data.couple.surplusCarOwnership.perc50,
  couple_maxBorrowingRent: data.couple.maxBorrowing.rent,
  couple_housingCostDiscountPerc50: data.couple.housingCostDiscount.perc50,
  couple_netIncomeModal1_5x: data.couple.netIncome.modal1_5x,
  couple_surplusHomeOwnershipPerc100: data.couple.surplusHomeOwnership.perc100,
  couple_basicStandardHomeowners: data.couple.basicStandard.homeowners,
  couple_surplusCarOwnershipPerc100: data.couple.surplusCarOwnership.perc100,
  couple_maxBorrowingHomeowners: data.couple.maxBorrowing.homeowners,
  couple_housingCostDiscountPerc100: data.couple.housingCostDiscount.perc100,
  couple_rate: data.couple.rate,
  couple_minimumIncome: data.couple.minimumIncome,
  couple_surplusHomeOwnershipPerc150: data.couple.surplusHomeOwnership.perc150,
  couple_basicStandardResidental: data.couple.basicStandard.homeowners,
  couple_surplusCarOwnershipPerc150: data.couple.surplusCarOwnership.perc150,
  couple_maxBorrowingResidental: data.couple.maxBorrowing.homeowners,
  couple_housingCostDiscountPerc150: data.couple.housingCostDiscount.perc150,
  couple_incomeTypeEmployedUnderContract:
    data.couple.incomeType.employed_under_contract,
  couple_incomeTypePensionAow: data.couple.incomeType.pension_aow,
  couple_incomeTypeSocialSecurity: data.couple.incomeType.social_security,
  couple_incomeTypeOther: data.couple.incomeType.other,

  singleWithChildren_netIncomeModal: data.singleWithChildren.netIncome.modal,
  singleWithChildren_surplusHomeOwnershipPerc50:
    data.singleWithChildren.surplusHomeOwnership.perc50,
  singleWithChildren_basicStandardRent:
    data.singleWithChildren.basicStandard.rent,
  singleWithChildren_surplusCarOwnershipPerc50:
    data.singleWithChildren.surplusCarOwnership.perc50,
  singleWithChildren_maxBorrowingRent:
    data.singleWithChildren.maxBorrowing.rent,
  singleWithChildren_housingCostDiscountPerc50:
    data.singleWithChildren.housingCostDiscount.perc50,
  singleWithChildren_netIncomeModal1_5x:
    data.singleWithChildren.netIncome.modal1_5x,
  singleWithChildren_surplusHomeOwnershipPerc100:
    data.singleWithChildren.surplusHomeOwnership.perc100,
  singleWithChildren_basicStandardHomeowners:
    data.singleWithChildren.basicStandard.homeowners,
  singleWithChildren_surplusCarOwnershipPerc100:
    data.singleWithChildren.surplusCarOwnership.perc100,
  singleWithChildren_maxBorrowingHomeowners:
    data.singleWithChildren.maxBorrowing.homeowners,
  singleWithChildren_housingCostDiscountPerc100:
    data.singleWithChildren.housingCostDiscount.perc100,
  singleWithChildren_rate: data.singleWithChildren.rate,
  singleWithChildren_minimumIncome: data.singleWithChildren.minimumIncome,
  singleWithChildren_surplusHomeOwnershipPerc150:
    data.singleWithChildren.surplusHomeOwnership.perc150,
  singleWithChildren_basicStandardResidental:
    data.singleWithChildren.basicStandard.homeowners,
  singleWithChildren_surplusCarOwnershipPerc150:
    data.singleWithChildren.surplusCarOwnership.perc150,
  singleWithChildren_maxBorrowingResidental:
    data.singleWithChildren.maxBorrowing.homeowners,
  singleWithChildren_housingCostDiscountPerc150:
    data.singleWithChildren.housingCostDiscount.perc150,
  singleWithChildren_incomeTypeEmployedUnderContract:
    data.singleWithChildren.incomeType.employed_under_contract,
  singleWithChildren_incomeTypePensionAow:
    data.singleWithChildren.incomeType.pension_aow,
  singleWithChildren_incomeTypeSocialSecurity:
    data.singleWithChildren.incomeType.social_security,
  singleWithChildren_incomeTypeOther: data.singleWithChildren.incomeType.other,
  childrenBenefitFactor: data.singleWithChildren.childrenBenefitFactor,

  coupleWithChildren_netIncomeModal: data.coupleWithChildren.netIncome.modal,
  coupleWithChildren_surplusHomeOwnershipPerc50:
    data.coupleWithChildren.surplusHomeOwnership.perc50,
  coupleWithChildren_basicStandardRent:
    data.coupleWithChildren.basicStandard.rent,
  coupleWithChildren_surplusCarOwnershipPerc50:
    data.coupleWithChildren.surplusCarOwnership.perc50,
  coupleWithChildren_maxBorrowingRent:
    data.coupleWithChildren.maxBorrowing.rent,
  coupleWithChildren_housingCostDiscountPerc50:
    data.coupleWithChildren.housingCostDiscount.perc50,
  coupleWithChildren_netIncomeModal1_5x:
    data.coupleWithChildren.netIncome.modal1_5x,
  coupleWithChildren_surplusHomeOwnershipPerc100:
    data.coupleWithChildren.surplusHomeOwnership.perc100,
  coupleWithChildren_basicStandardHomeowners:
    data.coupleWithChildren.basicStandard.homeowners,
  coupleWithChildren_surplusCarOwnershipPerc100:
    data.coupleWithChildren.surplusCarOwnership.perc100,
  coupleWithChildren_maxBorrowingHomeowners:
    data.coupleWithChildren.maxBorrowing.homeowners,
  coupleWithChildren_housingCostDiscountPerc100:
    data.coupleWithChildren.housingCostDiscount.perc100,
  coupleWithChildren_rate: data.coupleWithChildren.rate,
  coupleWithChildren_minimumIncome: data.coupleWithChildren.minimumIncome,
  coupleWithChildren_surplusHomeOwnershipPerc150:
    data.coupleWithChildren.surplusHomeOwnership.perc150,
  coupleWithChildren_basicStandardResidental:
    data.coupleWithChildren.basicStandard.homeowners,
  coupleWithChildren_surplusCarOwnershipPerc150:
    data.coupleWithChildren.surplusCarOwnership.perc150,
  coupleWithChildren_maxBorrowingResidental:
    data.coupleWithChildren.maxBorrowing.homeowners,
  coupleWithChildren_housingCostDiscountPerc150:
    data.coupleWithChildren.housingCostDiscount.perc150,
  coupleWithChildren_incomeTypeEmployedUnderContract:
    data.coupleWithChildren.incomeType.employed_under_contract,
  coupleWithChildren_incomeTypePensionAow:
    data.coupleWithChildren.incomeType.pension_aow,
  coupleWithChildren_incomeTypeSocialSecurity:
    data.coupleWithChildren.incomeType.social_security,
  coupleWithChildren_incomeTypeOther: data.coupleWithChildren.incomeType.other,
});

import { MutationFunction } from "@tanstack/react-query";

import { MutationVariables } from "types";

import { encodePath } from ".";
import { BASE_PATH } from "./defaultQueryFn";
import { fetchWithInterceptor } from "./fetchWithInterceptor";

export const defaultMutationFn: MutationFunction = async (variables) => {
  const { path, method, body, options } = variables as MutationVariables;

  const token = options?.token;

  const authHeader: Record<string, string> = token
    ? { Authorization: `Bearer ${token}` }
    : {};

  try {
    const res = await fetchWithInterceptor(`${BASE_PATH}${encodePath(path)}`, {
      method,
      headers: {
        "Content-Type": "application/json",
        ...authHeader,
      },
      ...(body && { body: JSON.stringify(body) }),
    });

    if (!res?.ok) throw res;

    if (res.status === 204) return;

    const json = await res.json();

    return json;
  } catch (err) {
    if (typeof err === "string") throw Error(err);
    else throw err;
  }
};

const BASE_INQUIRY_PATH = "/inquiry";

const APP_URI = {
  ANY: "*",
  HOME: BASE_INQUIRY_PATH,

  STEP_1: `${BASE_INQUIRY_PATH}/step-1`,

  STEP_2: `${BASE_INQUIRY_PATH}/step-2-1`,
  STEP_2_SINGLE_WITH_CHILDREN: `${BASE_INQUIRY_PATH}/step-2-2-living-with-children`,

  STEP_3: `${BASE_INQUIRY_PATH}/step-3`,

  STEP_4: `${BASE_INQUIRY_PATH}/step4-1`,
  STEP_4_RESIDENT: `${BASE_INQUIRY_PATH}/step4-3-resident`,
  STEP_4_MONTHLY_COMMITMENTS: `${BASE_INQUIRY_PATH}/step4-4-monthly-commitments`,

  STEP_5: `${BASE_INQUIRY_PATH}/step-5`,

  RESULT: `${BASE_INQUIRY_PATH}/result`,

  ADMIN_CONFIG: "/config",
  ADMIN_LOAN_RATES: "/loan-rates",
  ADMIN_LOGIN: "/login",
  CREDENTIALS: "/credentials",

  COOKIES_POLICY: `${BASE_INQUIRY_PATH}/cookies-policy`,
  PRIVACY_POLICY: `${BASE_INQUIRY_PATH}/privacy-policy`,
  TERMS_OF_SERVICE: `${BASE_INQUIRY_PATH}/terms-of-service`,
};

const calculatorBasePath = "/calculator";
const parametersBasePath = "/parameters";
const aprTableBasePath = "/apr-table";
const adminBasePath = "/admin";

const API_PATHS = {
  VEHICLES: `${calculatorBasePath}/vehicles`,
  LOAN_APPROVAL: `${calculatorBasePath}/loan-approval`,

  PARAMETERS: `${adminBasePath}${parametersBasePath}`,
  PARAMETERS_DRAFT: `${adminBasePath}${parametersBasePath}/draft`,
  PARAMETERS_PROMOTE: `${adminBasePath}${parametersBasePath}/promote`,

  APR_TABLE: `${adminBasePath}${aprTableBasePath}`,

  SAVE_DATA: `${calculatorBasePath}/save-data`,
};

const DEFAULT_APP_URI_ROUTE = APP_URI.HOME;

export { API_PATHS, APP_URI, BASE_INQUIRY_PATH, DEFAULT_APP_URI_ROUTE };

import { pxToRem } from "@faxi/web-css-utilities";
import styled from "styled-components";
import theme from "theme";

const StyledVehicleStepHeader = styled.div`
  background-color: var(--GRAY-FB);
  padding-top: ${pxToRem("340px")};

  &.fc-vehicle-header--no-data {
    padding-top: ${pxToRem("140px")};
  }

  &.fc-vehicle-header--title section {
    margin-top: 18px;
  }

  .subtitle {
    margin: 0 auto ${theme.sizes.SIZE_16};
    padding: ${theme.sizes.SIZE_16};
    background-color: var(--GRAY-EF);

    > div {
      max-width: 436px;
      margin: 0 auto ${theme.sizes.SIZE_16};

      padding: ${theme.sizes.SIZE_16};

      p {
        text-align: center;
      }
    }
  }
`;

export { StyledVehicleStepHeader };

import styled from "styled-components";

import { fontSize } from "@faxi/web-css-utilities";
import VehicleStepHeader from "components/organisms/VehicleStepHeader";

const StyledStepPage = styled(VehicleStepHeader)`
  strong {
    ${fontSize("15px")};
  }
`;

export { StyledStepPage };

import React from "react";

import { StyledCircularSpinner } from "./CircularSpinner.styled";

export type CircularSpinnerProps = {
  className?: string;
};

const CircularSpinner: React.FC<CircularSpinnerProps> = ({ className }) => (
  <StyledCircularSpinner className={className} />
);

export default CircularSpinner;

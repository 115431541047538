import { flex, fontSize } from "@faxi/web-css-utilities";
import styled from "styled-components";

const StyledDetailsInfo = styled.div`
  ${flex("column", "flex-start", "flex-start")};
`;

const StyledKeyText = styled.p`
  ${fontSize("14px", "20px")};

  color: var(--TEXT_SECONDARY);
  font-weight: 400;
`;

const StyledValueText = styled.p`
  ${fontSize("16px", "23px")};
  font-weight: 600;
`;

export { StyledDetailsInfo, StyledKeyText, StyledValueText };

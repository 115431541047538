import { theme } from "@faxi/web-component-library";
import { flex, position, pxToRem } from "@faxi/web-css-utilities";
import styled from "styled-components";

const StyledFormActions = styled.div`
  ${position("fixed", "bottom 0 left 0 right 0 top auto")};
  z-index: 3;

  padding: ${pxToRem("16px")};
  background-color: var(--WHITE);

  width: 100%;
  max-width: 100%;

  > div {
    ${flex("row", "center", "center")};
    gap: ${theme.sizes.SIZE_8};

    width: 100%;
    max-width: 404px;
    margin: auto;

    > button,
    > a {
      ${flex("row", "center", "center")};

      border-radius: 999px;
      width: 100%;

      padding: ${pxToRem("13px")} ${pxToRem("16px")};
      font-size: ${pxToRem("15px")};

      min-height: ${pxToRem("44px")};
      max-height: ${pxToRem("44px")};
    }
  }
`;

export { StyledFormActions };

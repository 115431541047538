import classNames from "classnames";
import React from "react";

import { StyledCallUs } from "./CallUs.styled";

export type CallUsProps = {
  href: string;
  phoneText: string;
  className?: string;
};

const CallUs: React.FC<CallUsProps> = ({ href, phoneText, className }) => (
  <StyledCallUs href={href} className={classNames("link-with-icon", className)}>
    {phoneText}
    <img src="/icons/phone.svg" alt="Phone icon" />
  </StyledCallUs>
);

export default CallUs;

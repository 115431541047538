import React, { ReactNode } from "react";

import { StyledHr } from "components/atoms/BaseHeader/BaseHeader.styled";
import { useScrollToTop } from "hooks";
import HeaderBranding from "../HeaderBranding";
import { StyledLegalDocumentsWrapper } from "./LegalDocumentsWrapper.styled";

export type LegalDocumentsWrapperProps = {
  title: string;
  children?: ReactNode;
};

const LegalDocumentsWrapper: React.FC<LegalDocumentsWrapperProps> = ({
  title,
  children,
}) => {
  useScrollToTop();

  return (
    <>
      <HeaderBranding />
      <StyledLegalDocumentsWrapper>
        <h1>{title}</h1>
        <StyledHr />

        {children}
      </StyledLegalDocumentsWrapper>
    </>
  );
};

export default LegalDocumentsWrapper;

import { theme } from "@faxi/web-component-library";
import { flex, fontSize } from "@faxi/web-css-utilities";
import styled from "styled-components";

const StyledHeader = styled.header`
  position: sticky;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  a {
    color: black;
    text-decoration: none;

    &.active {
      font-weight: bold;
    }
  }
`;

const StyledAdminPage = styled.main`
  ${flex("column")};
  overflow-y: scroll;
  max-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  background-color: var(--WHITE);

  padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_104};

  form {
    width: 100%;
    max-width: 1764px;
    margin: 0 auto;
  }
`;

const Logo = styled.img`
  height: 20px;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const LogoutButton = styled.button`
  ${fontSize("16px")};

  background-color: transparent;
  border: none;
  font-weight: 600;
  padding: 10px 20px;
  cursor: pointer;
`;

export { Logo, LogoutButton, NavLinks, StyledAdminPage, StyledHeader };

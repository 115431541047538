import { pxToRem } from "@faxi/web-css-utilities";
import styled from "styled-components";

import { theme } from "@faxi/web-component-library";
import { VehicleStepHeader } from "components";

const StyledStep5PersonalDetails = styled(VehicleStepHeader)`
  .fc-step-5 {
    &__confirm-contact {
      background-color: var(--GRAY-EF);
      padding: ${pxToRem("16px")};
      margin-top: ${theme.sizes.SIZE_12};
    }

    &__privacy-statement {
      margin-bottom: ${theme.sizes.SIZE_32};

      a {
        color: black;
        font-weight: 500;
      }
    }
  }
`;

export { StyledStep5PersonalDetails };

import { size } from "@faxi/web-css-utilities";
import styled, { keyframes } from "styled-components";

const spinnerAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const StyledCircularSpinner = styled.div`
  ${size("20px")};
  border: 2px solid var(--GRAY-90);
  border-radius: 50%;
  border-top-color: var(--PRIMARY_1_1);
  margin: auto;
  box-sizing: border-box;
  animation: ${spinnerAnimation} 0.7s linear infinite;
`;

export { StyledCircularSpinner };

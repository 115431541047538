import { useUtilities } from "@faxi/web-component-library";
import { Form } from "@faxi/web-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { useAuthenticateUser } from "../../../utils/cognitoHostedAuth";

import { API_PATHS } from "common";
import { Loader } from "components";
import { useURLSearchParams } from "hooks";
import { MutationMethod, MutationVariables } from "types";

import { StyledCalculator } from "./Config.styled";
import { Table, TableMode } from "./Table.component";
import { CalculatorProps, ParametersTableResponse } from "./types";
import { generateTableData, parseTableData } from "./utils";

const Config: React.FC<CalculatorProps> = () => {
  const { userCredentials, isLoading: isAuthenticateLoading } =
    useAuthenticateUser();

  const queryClient = useQueryClient();

  const { params: queryParams, setQueryParam } = useURLSearchParams();
  const { showSnackBar, prompts } = useUtilities();

  const isEditMode = queryParams.mode === TableMode.EDIT;

  const parametersKey = [
    `${API_PATHS.PARAMETERS}`,
    "",
    { token: userCredentials.id_token },
  ];

  const { mutate: saveParameters, isPending: isPendingSaveParameters } =
    useMutation<ParametersTableResponse, Error, MutationVariables>({
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: parametersKey });
        showSnackBar({
          text: "Successfully updated financial parameters data.",
          variant: "success",
        });
      },
      onError: (err) => {
        const defaultErrorMessage =
          "Oops! Something went wrong, new data could not be submitted.";

        showSnackBar({
          text: err.message || defaultErrorMessage,
          variant: "error",
        });
      },
    });

  const { mutate: promoteToActive, isPending: isPendingPromoteToActive } =
    useMutation<ParametersTableResponse, Error, MutationVariables>({
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: parametersKey });
        showSnackBar({
          text: "Successfully promoted financial parameters to live",
          variant: "success",
        });
        setQueryParam("mode", TableMode.LIVE);
      },
      onError: (err) => {
        const defaultErrorMessage =
          "Oops! Something went wrong, we failed to promote financial parameters to live.";

        showSnackBar({
          text: err.message || defaultErrorMessage,
          variant: "error",
        });
      },
    });

  const { data, isLoading } = useQuery<ParametersTableResponse>({
    queryKey: parametersKey,
  });

  const onPromote = async () => {
    const isPromotionConfirmed = await prompts.standard({
      title: "Confirm Promotion",
      content: "Are you sure you want to promote the draft parameters to live?",
      submitBtnText: "Promote",
      cancelBtnText: "Cancel",
      position: "center",
    });

    if (isPromotionConfirmed)
      promoteToActive({
        path: API_PATHS.PARAMETERS_PROMOTE,
        method: MutationMethod.POST,
        options: { token: userCredentials.id_token },
      });
  };

  const parameters = isEditMode
    ? data?.DRAFT.parameters
    : data?.ACTIVE.parameters;

  const isPending = isPendingSaveParameters || isPendingPromoteToActive;
  const canPromoteData = data?.ACTIVE.version !== data?.DRAFT.version;

  if (isLoading || isAuthenticateLoading || !parameters)
    return <Loader isRedirecting={false} />;

  return (
    <StyledCalculator>
      <Form
        onSubmit={async (data) =>
          saveParameters({
            path: API_PATHS.PARAMETERS_DRAFT,
            method: MutationMethod.PUT,
            body: parseTableData(data),
            options: { token: userCredentials.id_token },
          })
        }
        initialData={generateTableData(parameters)}
      >
        {isPending || isLoading ? (
          <Loader className="table-loader" isRedirecting={false} />
        ) : (
          <>
            {Object.entries(parameters).map(([key, value], index) => (
              <Table
                params={value}
                key={key}
                title={key}
                hasAction={index === 0}
                canPromoteData={canPromoteData}
                onPromote={onPromote}
              />
            ))}
          </>
        )}
      </Form>
    </StyledCalculator>
  );
};

export default Config;

import { StorageKeys } from "common";
import { storageService } from "services";

type FormResult = {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
};
export type PersonalDetails = Omit<FormResult, "phoneNumber"> & {
  telephone: string;
};

export const getPersonalDetails = (): Partial<PersonalDetails> => {
  const formResult = storageService.getItem<FormResult>(
    StorageKeys.FORM_STORAGE_KEY,
  );

  if (!formResult) return {};

  const { email, firstName, lastName, phoneNumber } = formResult;

  return {
    email,
    firstName,
    lastName,
    telephone: phoneNumber,
  };
};

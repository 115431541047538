import { Route, useLocation } from "react-router-dom";

import { useAuthenticateUser } from "./AuthenticateUserProvider";
import LoginRedirect from "./LoginRedirect";

const PrivateRoute = ({ component: Component, ...rest }: any): JSX.Element => {
  const { isAuthenticated } = useAuthenticateUser();
  const location = useLocation();

  return !isAuthenticated ? (
    <Route
      {...rest}
      render={() => <LoginRedirect locationPath={location.pathname} />}
    />
  ) : (
    <Route {...rest} render={(props) => <Component {...props} />} />
  );
};

export default PrivateRoute;

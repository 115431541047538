import { fontSize } from "@faxi/web-css-utilities";
import styled from "styled-components";

const StyledRequiredLabel = styled.p`
  ${fontSize("14px")};
  font-weight: 500;

  > span {
    margin: 0 2px;
    color: var(--PRIMARY_1_1);
  }
`;

export { StyledRequiredLabel };

import React, { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

import { APP_URI } from "common";
import { useAuthenticateUser } from "utils/cognitoHostedAuth";

import {
  Logo,
  LogoutButton,
  NavLinks,
  StyledAdminPage,
  StyledHeader,
} from "./Header.styled";
import { storageService } from "services";

export type HeaderProps = { children?: ReactNode; className?: string };

const links = [
  { to: APP_URI.ADMIN_CONFIG, text: "Config" },
  { to: APP_URI.ADMIN_LOAN_RATES, text: "Rates" },
];

const Header: React.FC<HeaderProps> = ({ children, className }) => {
  const { logoutRedirectUrl } = useAuthenticateUser();
  const location = useLocation();

  const handleLogout = () => {
    storageService.removeItem("userCredentials");
    window.location.href = logoutRedirectUrl;
  };

  return (
    <>
      <StyledHeader>
        <Logo src="/icons/car-match.svg" alt="Logo" />
        <NavLinks>
          {links.map((link) => (
            <Link
              key={link.to}
              to={link.to}
              className={location.pathname === link.to ? "active" : ""}
            >
              {link.text}
            </Link>
          ))}
        </NavLinks>

        <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
      </StyledHeader>

      <StyledAdminPage className={className}>{children}</StyledAdminPage>
    </>
  );
};

export default Header;

import Lottie from "lottie-react";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import lottieLoaderData from "./loader.json";

import {
  Loader,
  LoaderLottie,
  StyledRedirectingFooter,
  StyledRedirectingText,
} from "./Loader.styled";

export type SkeletonLoadingProps = {
  isRedirecting?: boolean;
  className?: string;
};

const SkeletonLoading: FC<SkeletonLoadingProps> = ({
  isRedirecting,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Loader className={className}>
      {isRedirecting && (
        <StyledRedirectingText>
          {t("redirecting")} <img src="/icons/car-match.svg" alt="Logo icon" />
        </StyledRedirectingText>
      )}

      <LoaderLottie>
        <Lottie animationData={lottieLoaderData} />
      </LoaderLottie>

      {isRedirecting && (
        <StyledRedirectingFooter>
          <strong>{t("redirecting_poweredby")}</strong>
          <img src="/icons/logo-with-text.svg" alt="TSF Logo with text" />
        </StyledRedirectingFooter>
      )}
    </Loader>
  );
};

export default SkeletonLoading;

import { pxToRem } from "@faxi/web-css-utilities";
import styled from "styled-components";

const StyledInputField = styled.div`
  .input__container {
    z-index: 2;
  }

  & input {
    border-bottom: 1px solid var(--GRAY-CD) !important;
    border-radius: 6px !important;

    &:focus-within {
      outline: none !important;
      border-color: var(--ACCENT_1_1) !important;
    }

    &.input__container__input--has-error {
      border-color: var(--ALERT_ERROR_1_1) !important;
    }
  }

  label,
  small {
    display: flex;
    width: 100%;
    text-align: start;
  }

  small {
    color: var(--TEXT_SECONDARY);
    margin-top: ${pxToRem("8px")};
  }
`;

export { StyledInputField };

import parse from "html-react-parser";
import React from "react";
import { useTranslation } from "react-i18next";

import { APP_URI } from "common";
import { LegalDocumentsWrapper } from "components";
import { breakTranslationNewlines } from "utils";

export type CookiesPolicyProps = {};

const CookiesPolicy: React.FC<CookiesPolicyProps> = () => {
  const { t } = useTranslation();

  return (
    <LegalDocumentsWrapper title={t("tfs_cookiepolicy_header")}>
      {parse(breakTranslationNewlines(t("tfs_cookiepolicy_text1")))}

      <table>
        <thead>
          <tr>
            <th>{t("tfs_cookiepolicy_column1_header")}</th>
            <th>{t("tfs_cookiepolicy_column2_header")}</th>
            <th>{t("tfs_cookiepolicy_column3_header")}</th>
            <th>{t("tfs_cookiepolicy_column4_header")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ajs_anonymous_id</td>
            <td>{t("tfs_cookiepolicy_column2_text")}</td>
            <td>{t("tfs_cookiepolicy_column3_text")}</td>
            <td>{t("tfs_cookiepolicy_column4_text1")}</td>
          </tr>
          <tr>
            <td>ajs_user_id</td>
            <td>{t("tfs_cookiepolicy_column2_text")}</td>
            <td>{t("tfs_cookiepolicy_column3_text")}</td>
            <td>{t("tfs_cookiepolicy_column4_text2")}</td>
          </tr>
        </tbody>
      </table>

      {parse(
        breakTranslationNewlines(
          t("tfs_cookiepolicy_text2", {
            policy_statement: `<a href="${APP_URI.PRIVACY_POLICY}">${t("tfs_cookiepolicy_statement").toLowerCase()}</a>`,
          }),
        ),
      )}
    </LegalDocumentsWrapper>
  );
};

export default CookiesPolicy;

import { storageService } from "services";
import {
  getConfig,
  getUserCredentialsData,
} from "utils/cognitoHostedAuth/config";
import { UserCredentialsState } from "utils/cognitoHostedAuth/interfaces";

const cognitoConfig = getConfig();

const refreshTokenFn = async (refreshToken?: string | null) => {
  const { body, getUserCredentialsUrl } =
    getUserCredentialsData(cognitoConfig)();

  if (!refreshToken) throw new Error("No refresh token available");

  const response = await fetch(getUserCredentialsUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    },
    body,
  });

  if (!response.ok) throw new Error("Failed to refresh token");

  const data = await response.json();

  storageService.updateItem("userCredentials", "id_token", data.id_token);

  return data as UserCredentialsState;
};

export const fetchWithInterceptor = async (
  url: string | URL | Request,
  options: RequestInit = {},
) => {
  const { refresh_token: refreshToken } =
    storageService.getItem<UserCredentialsState>("userCredentials") ?? {};

  let isError = false;
  let response;

  try {
    const res = await fetch(url, options);
    if (!res.ok && [401, 403].includes(res.status)) {
      isError = true;
    }
    response = res;
  } catch (error) {
    isError = false; // TODO: we should check this
  }

  if (isError) {
    try {
      const { id_token: token } = await refreshTokenFn(refreshToken);
      const retryOptions = {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      };
      response = await fetch(url, retryOptions);
    } catch (err) {
      window.location.href = "/login";
    }
  }

  return response;
};

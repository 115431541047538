import { StorageKeys } from "common";
import { storageService } from "services";
import { SaveDataResponse } from "types";

export const getSaveDataResponse = (): Partial<SaveDataResponse> => {
  const result = storageService.getItem<SaveDataResponse>(
    StorageKeys.SAVE_DATA_RESPONSE_KEY,
  );

  if (!result) return {};

  const { referenceNumber = "", personId } = result;

  return {
    referenceNumber,
    personId,
  };
};

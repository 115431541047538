import { FieldProps, useFieldError } from "@faxi/web-form";
import classNames from "classnames";
import { RequiredLabel } from "components/atoms";
import { FC, useCallback } from "react";
import Select, {
  CSSObjectWithLabel,
  GroupBase,
  MultiValue,
  OptionProps,
  Props as SelectProps,
  SingleValue,
} from "react-select";
import { StyledSelectField } from "./SelectField.styled";

type SelectOption = { value: string; label: string };

export type SelectFieldProps = FieldProps<string, (event: string) => void> & {
  className?: string;
  description?: string;
  disabled?: boolean;
  options: SelectOption[];
  value?: string;
  label?: string;
} & Omit<SelectProps<SelectOption>, "options">;

const customSelectStyles = {
  option: (
    provided: CSSObjectWithLabel,
    state: OptionProps<SelectOption, boolean, GroupBase<SelectOption>>,
  ) => ({
    ...provided,
    backgroundColor:
      state.isSelected || state.isFocused ? "var(--GRAY-F1)" : "var(--GRAY-FB)",
    color: "var(--TEXT_PRIMARY)",
    "&:hover": {
      backgroundColor: "var(--GRAY-F1)",
    },
  }),
  control: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    height: "44.5px",
    "&:focus-within": {
      boxShadow: "none",
      borderColor: "var(--ACCENT_1_1)",
    },
    "&:hover .react-select__indicators svg": {
      transform: state.selectProps.menuIsOpen
        ? "rotate(180deg)"
        : "rotate(0deg)",
      transition: "transform 0.2s ease",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    svg: {
      transform: state.selectProps.menuIsOpen
        ? "rotate(180deg)"
        : "rotate(0deg)",
      transition: "transform 0.2s ease",
    },
  }),
};

const SelectField: FC<SelectFieldProps> = (props: SelectFieldProps) => {
  const {
    className,
    description,
    dirty,
    error,
    touched,
    value,
    options = [],
    onChange,
    label,
    ...rest
  } = props;

  const showError = useFieldError(props);

  const selectOnChange = useCallback(
    (value: MultiValue<SelectOption> | SingleValue<SelectOption>) => {
      const newValue = value as SingleValue<SelectOption>;
      onChange?.(newValue ? newValue.value : "");
    },
    [onChange],
  );

  return (
    <StyledSelectField className={classNames("kinto-select-field", className)}>
      <RequiredLabel className="kinto-select-field__label">
        {label}
      </RequiredLabel>

      <Select
        value={options.find((option) => option.value === value)}
        onChange={selectOnChange}
        options={options}
        styles={customSelectStyles}
        {...rest}
      />
      {showError && <small className="error-text">{String(error)}</small>}
    </StyledSelectField>
  );
};

export default SelectField;

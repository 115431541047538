import { LoanAmountStatus, LoanApprovalSingleResponse } from "types";

export const prepareResultDisplayedTrackEvent = ({
  status,
  loanAmount,
  currency,
  loanTerm,
  monthlyPayment,
  rate,
  totalRepayable,
}: LoanApprovalSingleResponse & { referenceNumber?: string }) => {
  const isApproved =
    [LoanAmountStatus.APPROVED, LoanAmountStatus.PARTIALLY_APPROVED].includes(
      status!,
    ) ?? false;

  return isApproved
    ? {
        approved: status,
        loanAmount,
        currency,
        term: loanTerm,
        monthlyPayment,
        interestRate: rate,
        totalRepayable,
      }
    : {
        approved: status,
      };
};

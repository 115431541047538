import { theme } from "@faxi/web-component-library";
import { flex, fontSize } from "@faxi/web-css-utilities";
import styled from "styled-components";

const StyledErrorTemplate = styled.div`
  background-color: var(--GRAY-EF);
  min-height: 100vh;
  padding: ${theme.sizes.SIZE_160} ${theme.sizes.SIZE_16};

  .fc-error-template {
    &__card {
      ${flex("column", "flex-start", "center")};
      gap: ${theme.sizes.SIZE_16};

      max-width: 404px;
      margin: auto;
      padding: ${theme.sizes.SIZE_24};

      b {
        ${fontSize("20px")};
      }
    }
  }
`;

export { StyledErrorTemplate };

import { theme } from "@faxi/web-component-library";
import { fontSize } from "@faxi/web-css-utilities";
import styled from "styled-components";

const StyledLegalDocumentsWrapper = styled.div`
  max-width: ${theme.sizes.SIZE_1016};
  margin: auto;
  margin-bottom: ${theme.sizes.SIZE_104};

  padding: 0 ${theme.sizes.SIZE_16};

  color: var(--TEXT_PRIMARY);
  line-height: 22px;

  h1 {
    text-align: center;
  }

  h2 {
    ${fontSize("18px")};
    font-weight: 400;
  }

  p {
    line-height: 22px;
    margin: ${theme.sizes.SIZE_20} 0;
  }

  hr {
    margin-bottom: ${theme.sizes.SIZE_24};
  }

  ul {
    margin: 0;
  }

  li {
    margin: ${theme.sizes.SIZE_12} 0;
  }

  a {
    color: var(--GRAY-28);
    cursor: pointer;

    &:hover {
      color: var(--GRAY-6C);
    }
  }

  section {
    margin: 0;
    gap: 0;
  }
`;

export { StyledLegalDocumentsWrapper };

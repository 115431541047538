import { Route } from "react-router-dom";
import {
  AuthenticateProvider,
  Credentials,
  PrivateRoute,
} from "../../utils/cognitoHostedAuth";
import LoginRedirect from "../../utils/cognitoHostedAuth/LoginRedirect";
import { Config } from "./Config";
import { LoanRates } from "./LoanRates";
import { getConfig } from "utils/cognitoHostedAuth/config";
import { APP_URI } from "common";

const AdminRouter = () => {
  const config = getConfig();

  return (
    <AuthenticateProvider config={config}>
      <PrivateRoute path={APP_URI.ADMIN_CONFIG} component={Config} />
      <PrivateRoute path={APP_URI.ADMIN_LOAN_RATES} component={LoanRates} />
      <Route path={APP_URI.ADMIN_LOGIN} component={LoginRedirect} />
      <Route path={APP_URI.CREDENTIALS} exact component={Credentials} />
    </AuthenticateProvider>
  );
};

export default AdminRouter;

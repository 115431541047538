import styled from "styled-components";

import { theme } from "@faxi/web-component-library";
import { fontSize } from "@faxi/web-css-utilities";
import { VehicleStepHeader } from "components";

const StyledStep3 = styled(VehicleStepHeader)`
  .field-modal-label {
    margin-bottom: ${theme.sizes.SIZE_8};
    ${fontSize("14px")};
    font-weight: 500;
    color: var(--SHADE_1_1);
  }
`;

export { StyledStep3 };

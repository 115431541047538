import React from "react";
import { NavLinkProps } from "react-router-dom";

import { StyledButtonLink } from "./ButtonLink.styled";

const ButtonLink: React.FC<NavLinkProps> = (props) => (
  <StyledButtonLink {...props} />
);

export default ButtonLink;

import { createContext } from "react";
import { Vehicle } from "types";

export type VehicleContextData = {
  data?: Vehicle;
  isLoading: boolean;
  error: unknown;
};

export default createContext<VehicleContextData>({
  isLoading: false,
  error: false,
});

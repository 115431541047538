import { theme } from "@faxi/web-component-library";
import { flex, fontSize, mobile } from "@faxi/web-css-utilities";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

const StyledFooterLinks = styled.div`
  ${flex("row", "space-between", "flex-start")}
  gap: 10px;
  width: 100%;
  text-align: center;
  padding: 10px;

  border-top: 1px solid var(--GRAY-90);
  ${fontSize(theme.fontSizes.FONT_14)};

  ${mobile(css`
    flex-direction: column;
  `)}
`;

const StyledFooterLink = styled(NavLink)`
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export { StyledFooterLink, StyledFooterLinks };

import React, { ReactNode, useState } from "react";

import { Button, Modal } from "@faxi/web-component-library";
import { StyledOptionModalLabel } from "./OptionModalLabel.styled";

export type OptionModalLabelProps = {
  className?: string;
  labelClassName?: string;
  label: ReactNode;
  labelPosition?: "left" | "right";
  modalProps: {
    title: string;
    description: ReactNode;
  };
};

const OptionModalLabel: React.FC<OptionModalLabelProps> = ({
  label,
  modalProps,
  className,
  labelClassName,
  labelPosition = "left",
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <StyledOptionModalLabel className={className}>
      <span className={labelClassName}>
        {labelPosition === "left" && label}

        <img
          src="/icons/circle-info.svg"
          alt={`Open ${label} info modal`}
          onClick={() => setIsOpen(true)}
        />

        {labelPosition === "right" && label}
      </span>

      {isOpen && (
        <Modal
          hasCloseButton={false}
          onClose={() => setIsOpen(false)}
          title={modalProps.title}
          footer={<Button onClick={() => setIsOpen(false)}>OK</Button>}
        >
          {modalProps.description}
        </Modal>
      )}
    </StyledOptionModalLabel>
  );
};

export default OptionModalLabel;

import {
  Button,
  RadioGroup,
  useCallbackRef,
} from "@faxi/web-component-library";
import { FormField, FormRef, useFormRefValues } from "@faxi/web-form";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  APP_URI,
  DEPENDENT_FIELDS,
  FormSteps,
  getYesNoOptions,
  LivingSituation,
  StorageKeys,
  YesNo,
} from "common";

import {
  FormActions,
  FormStep,
  NumberInputField,
  RequiredLabel,
} from "components";

import { useAnalytics } from "providers";
import { storageService } from "services";
import { clearFormFields, validations } from "utils";

import { StyledStep4 } from "./Step4.styled";

export type Step4Props = {};
export type Step4FormValue = {
  livingSituation: LivingSituation;
};

const onLivingSituationChange = () => {
  clearFormFields(DEPENDENT_FIELDS.livingSituation);
};

const onRentalAllowanceChange = (doYouReceiveMonthlyRentAllowance: string) => {
  if (doYouReceiveMonthlyRentAllowance === YesNo.NO)
    clearFormFields(DEPENDENT_FIELDS.doYouReceiveMonthlyRentAllowance);
};

const Step4: React.FC<Step4Props> = () => {
  const [form, formRef] = useCallbackRef<FormRef>();
  const { t } = useTranslation();

  const history = useHistory();
  const analytics = useAnalytics();

  const livingSitutation = useFormRefValues(form, "livingSituation");
  const doYouReceiveMonthlyRentAllowance = useFormRefValues(
    form,
    "doYouReceiveMonthlyRentAllowance",
  );

  const handleTrackEvent = useCallback(() => {
    analytics.track("Next Step", {
      stepCompleted: history.location.pathname,
    });
  }, [history.location?.pathname, analytics]);

  const handleSubmit = useCallback(
    async (_: Step4FormValue) => {
      storageService.setItem(
        StorageKeys.COMPLETED_STEPS_STORAGE_KEY,
        FormSteps.STEP_4,
      );

      handleTrackEvent();

      history.push(APP_URI.STEP_4_MONTHLY_COMMITMENTS);
    },
    [history, handleTrackEvent],
  );

  const options = useMemo(
    () => [
      {
        value: LivingSituation.RENT,
        label: t("living_situation_rent"),
      },
      {
        value: LivingSituation.HOMEOWNERS,
        label: t("living_situation_homeowner"),
      },
      {
        value: LivingSituation.RESIDENTIAL,
        label: t("living_situation_resident"),
      },
    ],
    [t],
  );

  const formValues = useMemo(
    () => ({
      ...doYouReceiveMonthlyRentAllowance,
      ...livingSitutation,
    }),
    [livingSitutation, doYouReceiveMonthlyRentAllowance],
  );

  const isRenting = formValues?.livingSituation === LivingSituation.RENT;

  const isHomeOwner =
    formValues?.livingSituation === LivingSituation.HOMEOWNERS;

  const isReceivingMonthlyRentAllowance =
    formValues?.doYouReceiveMonthlyRentAllowance === YesNo.YES;

  return (
    <StyledStep4>
      <FormStep ref={formRef} onSubmit={handleSubmit}>
        <FormField
          name="livingSituation"
          options={options}
          component={RadioGroup}
          orientation="column"
          onChange={onLivingSituationChange}
          label={<RequiredLabel>{t("question_livingsituation")}</RequiredLabel>}
          validate={validations(t).selectAnyValue}
        />

        {isRenting && (
          <>
            <FormField
              name="grossHousingCost"
              label={t("living_situation_rent_howmuch")}
              required
              validate={validations(t).positiveNumber}
              component={NumberInputField}
            />
            <FormField
              name="doYouReceiveMonthlyRentAllowance"
              options={getYesNoOptions(t)}
              component={RadioGroup}
              orientation="column"
              onChange={onRentalAllowanceChange}
              validate={validations(t).selectAnyValue}
              label={
                <RequiredLabel>
                  {t("living_situation_rent_question_allowance")}
                </RequiredLabel>
              }
            />
            {isReceivingMonthlyRentAllowance && (
              <FormField
                name="rentalAllowance"
                label={t("living_situation_rent_question_allowance_howmuch")}
                required
                validate={validations(t).positiveNumber}
                component={NumberInputField}
              />
            )}
          </>
        )}
        {isHomeOwner && (
          <FormField
            name="grossHousingCost"
            label={t("living_situation_homowner_mortgage_howmuch")}
            required
            validate={validations(t).positiveNumberIncludesZero}
            component={NumberInputField}
          />
        )}
        <FormActions hasBackButton>
          <Button type="submit" disabled={!form?.syncFormValid}>
            {t("next")}
          </Button>
        </FormActions>
      </FormStep>
    </StyledStep4>
  );
};

export default Step4;

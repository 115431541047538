import _set from "lodash.set";

export type StorageServiceType = {
  ram: Record<string, string | undefined>;
  setItem: (key: string, value: string | Record<string, any>) => void;
  removeItem: (key: string) => void;
  getItem: <T>(key: string) => T;
  updateItem: (
    storageKey: string,
    objectKey: string,
    value: string | Record<string, any>,
  ) => void;
};

/**
 * This storage service provides a unified interface for interacting with localStorage and an
 * in-memory fallback (ram). It includes methods to set, remove, get, and update items,
 * ensuring data persistence even if localStorage is unavailable.
 */
export default {
  ram: {} as Record<string, string | undefined>,
  setItem(key: string, value: Record<string, unknown> | string): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn(e);
      this.ram[key] = JSON.stringify(value);
    }
  },
  removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.warn(e);
      this.ram[key] = undefined;
    }
  },
  getItem<T>(key: string): T {
    try {
      return JSON.parse(localStorage.getItem(key) as string);
    } catch (e) {
      console.warn(e);
      return JSON.parse(this.ram[key] as string);
    }
  },
  updateItem(
    storageKey: string,
    objectKey: string,
    value: Record<string, unknown> | string,
  ): void {
    try {
      const currentData: Record<string, any> = this.getItem(storageKey) || {};

      _set(currentData, objectKey, value);

      this.setItem(storageKey, currentData);
    } catch (e) {
      console.warn(e);
      this.ram[objectKey] = JSON.stringify(value);
    }
  },
} as StorageServiceType;

import { Input, InputProps } from "@faxi/web-component-library";
import { FieldProps, useFieldError } from "@faxi/web-form";

import { StyledInputField } from "./InputField.styled";

type ValueOnChange = (event: React.ChangeEvent<HTMLInputElement>) => void;

export type InputFieldProps = FieldProps<string, ValueOnChange> & {
  description?: string;
} & InputProps;

export const InputField: React.FC<InputFieldProps> = (
  props: InputFieldProps,
): JSX.Element => {
  const {
    error,
    description,
    dirty,
    touched,
    requiredLabel = "",
    ...rest
  } = props;

  const showError = useFieldError(props);

  return (
    <StyledInputField>
      <Input
        error={showError}
        requiredLabel={requiredLabel}
        errorText={String(error)}
        {...rest}
      />
      {!!description && !showError && <small>{description}</small>}
    </StyledInputField>
  );
};

export default InputField;

import React from "react";

import { LegalDocumentsWrapper } from "components";

import { StyledSubheader } from "./TermsOfService.styled";

export type TermsOfServiceProps = {};

const TermsOfService: React.FC<TermsOfServiceProps> = () => (
  <LegalDocumentsWrapper title="TOYOTA VERKOOPVOORWAARDEN">
    <section>
      <StyledSubheader>
        Alle consumentenadviesprijzen zijn inclusief BTW, BPM en kosten rijklaar
        maken, recyclingbijdrage en leges. Op basis van de door de overheid
        bepaalde belastingmaatregelen heeft een personenwagen een korting of een
        toeslag op de BPM. Op de prijslijst is dit reeds verrekend.
      </StyledSubheader>
      <p>
        Het BPM bedrag wordt wettelijk bepaald op het moment van aanvraag van
        het kenteken deel 1A en is gebaseerd op de op dat moment geldende netto
        catalogusprijzen en emissiecijfers. Het BPM bedrag op de prijslijst is
        gebaseerd op de situatie behorende bij de datum van de prijslijst en
        geldt uitsluitend als richtlijn. Hieraan kunnen derhalve geen rechten
        ontleend worden.
      </p>
    </section>
    <section>
      <p>Alle genoemde prijzen bij personenwagens zijn inclusief:</p>
      <ul>
        <li>
          5 jaar (max 200.000 km) garantie, opgebouwd uit 3 jaar
          fabrieksgarantie (max. 100.000 km) en 2 jaar + 100.000 km Toyota Extra
          Care garantie.
        </li>
        <li>
          5 jaar fabrieksgarantie (max. 100.000 km), aangevuld tot met 100.000
          km tot maxi 200.000 km. Op het Toyota Hybrid Synergy Drive® systeem.
          Dit is inclusief de hybride batterij van een Hybrid.
        </li>
        <li>12 jaar volledige carrosserie-garantie.</li>
        <li>
          3 jaar lakgarantie • Toyota Pechhulp (de 24-uurs mobiliteitsgarantie
          van Toyota in binnen- en buitenland) tot de eerstvolgende
          onderhoudsbeurt volgens fabrieksvoorschrift. (tijd en / of
          kilometers).
        </li>
      </ul>
    </section>
    <section>
      <p>Alle genoemde prijzen bij bedrijfswagens zijn inclusief:</p>
      <ul>
        <li>
          5 jaar (max 200.000 km) garantie, opgebouwd uit 3 jaar
          fabrieksgarantie (max. 100.000 km) en 2 jaar + 100.000 km Toyota Extra
          Care garantie. In het eerste jaar geldt geen kilometerbeperking
        </li>
        <li>
          6 jaar volledige carrosserie-garantie (op de laadbak 3 jaar garantie)
        </li>
        <li>3 jaar lakgarantie, (op de laadbak 1 jaar)</li>
      </ul>
    </section>
    <section>
      <h2>Kosten rijklaar maken</h2>
      <p>
        Kosten rijklaar maken hebben betrekking op transportkosten, in- en
        uitwendig reinigen, nulbeurt, kentekenplaten en handling- en
        administratiekosten. Deze kosten zijn reeds verwerkt in de
        consumentenadviesprijs.
      </p>
    </section>
    <section>
      <h2>Recyclingbijdrage</h2>
      <p>
        Sinds 1 januari 1995 wordt een verwijderingsbijdrage, die wettelijk
        verplicht is op grond van de Wet Milieubeheer, in rekening gebracht. De
        verwijderingsbijdrage komt ten gunste van Auto Recycling Nederland B.V.,
        een initiatief van de autobranche (RAI, BOVAG, FOCWA, STIBA en SVN).
        Auto Recycling Nederland B.V. heeft zich ten doel gesteld auto's aan het
        einde van de levensduur in te zamelen en op milieuvriendelijke wijze te
        demonteren voor hergebruik van het materiaal.
      </p>
      <p>
        De wettelijke verplichting van de verwijderingsbijdrage financiert het
        project en bepaalt in belangrijke mate het succes ten bate van een
        schoner milieu. De verwijderingsbijdrage heet vanaf 1 januari 2013
        recyclingbijdrage. Deze kosten zijn reeds verwerkt in de
        consumentenadviesprijs.
      </p>
    </section>
    <section>
      <h2>Leges- en registratiekosten</h2>
      <p>
        Sinds 1 januari 1998 worden van overheidswege leges- en
        registratiekosten kentekenbewijs en afdracht tenaamstelling in rekening
        gebracht. Deze kosten zijn reeds verwerkt in de consumentenadviesprijs.
      </p>
    </section>
  </LegalDocumentsWrapper>
);

export default TermsOfService;

import { Button } from "@faxi/web-component-library";

import { useURLSearchParams } from "hooks";
import { camelCaseToTitleCase } from "../../../utils";
import { EditableCell } from "../EditableCell";
import { HouseholdParameters } from "./types";

export enum TableMode {
  EDIT = "edit",
  LIVE = "live",
}

const Table = ({
  title,
  params,
  hasAction,
  onPromote,
  canPromoteData,
}: {
  title: string;
  hasAction?: boolean;
  params: HouseholdParameters;
  onPromote: () => void;
  canPromoteData: boolean;
}) => {
  const { params: queryParams, setQueryParam } = useURLSearchParams();

  const handleEditClick = () => {
    const mode = queryParams.mode;

    setQueryParam(
      "mode",
      mode === TableMode.EDIT ? TableMode.LIVE : TableMode.EDIT,
    );
  };

  const isEditable = queryParams.mode === TableMode.EDIT;
  const headerSufix = isEditable ? "(Draft)" : "(Live)";

  return (
    <div>
      <div className="fc-table-header-actions">
        <h2 className="fc-table-header-actions__text">
          <span>{camelCaseToTitleCase(title)}</span>
          <span>{headerSufix}</span>
        </h2>

        {hasAction && isEditable && (
          <div className="fc-table-header-actions__group">
            <Button
              className="fc-admin-outlined-button"
              onClick={() => handleEditClick()}
            >
              Cancel
            </Button>
            <Button className="fc-admin-outlined-button" type="submit">
              Save as draft
            </Button>
            <Button disabled={!canPromoteData} onClick={onPromote}>
              Promote to live
            </Button>
          </div>
        )}

        {hasAction && !isEditable && (
          <Button onClick={() => handleEditClick()}>See Draft</Button>
        )}
      </div>

      <table>
        <thead>
          <tr>
            <th>Net income</th>
            <th>Surplus home ownership</th>
            <th>Basic standard</th>
            <th>Surplus car ownership</th>
            <th>Max borrowing</th>
            <th>Housing cost discount</th>
            <th>Other</th>
            <th>Income type</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <EditableCell
              name={`${title}_netIncomeModal`}
              prefix="Modal"
              isEditable={isEditable}
              value={params.netIncome.modal}
            />
            <EditableCell
              name={`${title}_surplusHomeOwnershipPerc50`}
              prefix="50%"
              isEditable={isEditable}
              value={params.surplusHomeOwnership.perc50}
            />
            <EditableCell
              name={`${title}_basicStandardRent`}
              prefix="Rent"
              isEditable={isEditable}
              value={params.basicStandard.rent}
            />
            <EditableCell
              name={`${title}_surplusCarOwnershipPerc50`}
              prefix="50%"
              isEditable={isEditable}
              value={params.surplusCarOwnership.perc50}
            />
            <EditableCell
              name={`${title}_maxBorrowingRent`}
              prefix="Rent"
              isEditable={isEditable}
              value={params.maxBorrowing.rent}
            />
            <EditableCell
              name={`${title}_housingCostDiscountPerc50`}
              prefix="50%"
              isEditable={isEditable}
              value={params.housingCostDiscount.perc50}
            />
            <EditableCell
              name={`${title}_rate`}
              prefix="Rate"
              isEditable={isEditable}
              formatter={(value) => (value as number).toFixed(2)}
              value={params.rate}
            />
            <EditableCell
              name={`${title}_incomeTypeEmployedUnderContract`}
              prefix="Employed under contract"
              isEditable={isEditable}
              value={params.incomeType.employed_under_contract}
            />
          </tr>
          <tr>
            <EditableCell
              name={`${title}_netIncomeModal1_5x`}
              prefix="Modal 1_5x"
              isEditable={isEditable}
              value={params.netIncome.modal1_5x}
            />
            <EditableCell
              name={`${title}_surplusHomeOwnershipPerc100`}
              prefix="100%"
              isEditable={isEditable}
              value={params.surplusHomeOwnership.perc100}
            />
            <EditableCell
              name={`${title}_basicStandardHomeowners`}
              prefix="Homeowners"
              isEditable={isEditable}
              value={params.basicStandard.homeowners}
            />
            <EditableCell
              name={`${title}_surplusCarOwnershipPerc100`}
              prefix="100%"
              isEditable={isEditable}
              value={params.surplusCarOwnership.perc100}
            />
            <EditableCell
              name={`${title}_maxBorrowingHomeowners`}
              prefix="Homeowners"
              isEditable={isEditable}
              value={params.maxBorrowing.homeowners}
            />
            <EditableCell
              name={`${title}_housingCostDiscountPerc100`}
              prefix="100%"
              isEditable={isEditable}
              value={params.housingCostDiscount.perc100}
            />
            <EditableCell
              name={`${title}_minimumIncome`}
              prefix="Minimum Income"
              isEditable={isEditable}
              value={params.minimumIncome}
              formatter={(value) => (value as number).toFixed(2)}
            />
            <EditableCell
              name={`${title}_incomeTypePensionAow`}
              prefix="Pension AOW"
              isEditable={isEditable}
              value={params.incomeType.pension_aow}
            />
          </tr>
          <tr>
            <EditableCell
              name={`${title}_empty`}
              prefix=""
              isEditable={false}
              value=""
            />
            <EditableCell
              name={`${title}_surplusHomeOwnershipPerc150`}
              prefix="150%"
              isEditable={isEditable}
              value={params.surplusHomeOwnership.perc150}
            />
            <EditableCell
              name={`${title}_basicStandardResidental`}
              prefix="Residental"
              isEditable={isEditable}
              value={params.basicStandard.residential}
            />
            <EditableCell
              name={`${title}_surplusCarOwnershipPerc150`}
              prefix="150%"
              isEditable={isEditable}
              value={params.surplusCarOwnership.perc150}
            />
            <EditableCell
              name={`${title}_maxBorrowingResidental`}
              prefix="Residental"
              isEditable={isEditable}
              value={params.maxBorrowing.residential}
            />
            <EditableCell
              name={`${title}_housingCostDiscountPerc150`}
              prefix="150%"
              isEditable={isEditable}
              value={params.housingCostDiscount.perc150}
            />
            {params.childrenBenefitFactor ? (
              <EditableCell
                name="childrenBenefitFactor"
                prefix="Children benefit factor"
                isEditable={isEditable}
                value={params.childrenBenefitFactor}
              />
            ) : (
              <td />
            )}
            <EditableCell
              name={`${title}_incomeTypeSocialSecurity`}
              prefix="Social Security"
              isEditable={isEditable}
              value={params.incomeType.social_security}
            />
          </tr>
          <tr>
            <td colSpan={7}></td>

            <EditableCell
              name={`${title}_incomeTypeOther`}
              prefix="Other"
              isEditable={isEditable}
              value={params.incomeType.other}
            />
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export { Table };

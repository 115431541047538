import { theme } from "@faxi/web-component-library";
import { flex } from "@faxi/web-css-utilities";
import styled from "styled-components";

const StyledTabs = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${theme.sizes.SIZE_10};

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    border: none;
    width: 100%;
  }
`;

const Tab = styled.li`
  ${flex("row", "center", "center")};

  padding: ${theme.sizes.SIZE_10} 0;
  width: 100%;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s ease;
  text-align: center;

  &.active {
    border-bottom: 2px solid var(--PRIMARY_1_1);
    font-weight: 600;
    background-color: var(--GRAY-FB);

    small {
      color: var(--TEXT_SECONDARY);
      font-weight: normal;
    }
  }

  &:not(.active) {
    border-bottom: 2px solid var(--GRAY-DB);
    color: var(--TEXT_SECONDARY);
  }
`;

export { StyledTabs, Tab };

import { FC } from "react";
import { useTranslation } from "react-i18next";

import { APP_URI } from "common";

import { StyledFooterLink, StyledFooterLinks } from "./FooterLinks.styled";

const FooterLinks: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledFooterLinks className="ffc-footer-links">
      <StyledFooterLink to={APP_URI.COOKIES_POLICY}>
        {t("footer_cookie_link")}
      </StyledFooterLink>

      <StyledFooterLink to={APP_URI.PRIVACY_POLICY}>
        {t("footer_privacy_link")}
      </StyledFooterLink>

      <StyledFooterLink to={APP_URI.TERMS_OF_SERVICE}>
        {t("footer_termsconditions_link")}
      </StyledFooterLink>
    </StyledFooterLinks>
  );
};

export default FooterLinks;

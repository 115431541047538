import { InputProps } from "@faxi/web-component-library";
import { FieldProps, useFieldError } from "@faxi/web-form";
import React, { ChangeEvent } from "react";
import { NumericFormat } from "react-number-format";

import { RequiredLabel } from "components/atoms";
import { StyledNumberInputField } from "./NumberInputField.styled";
import { convertToNumber } from "utils";

type ValueOnChange = (event: React.ChangeEvent<HTMLInputElement>) => void;

export type NumberInputField = FieldProps<string, ValueOnChange> & {
  description?: string;
  type?: "password" | "tel" | "text" | undefined;
  defaultValue?: string;
  additionalOnChange?: (value: string) => void;
  prefix?: string;
  placeholder?: string;
  className?: string;
} & Omit<InputProps, "type">;

const DEFAULT_PREFIX = "€  ";
const DEFAULT_PLACEHOLDER = DEFAULT_PREFIX + "0";

const NumberInputField: React.FC<NumberInputField> = (props) => {
  const {
    error,
    description,
    dirty,
    touched,
    label,
    onChange,
    additionalOnChange,
    required,
    prefix,
    placeholder,
    className,
    value,
    ...rest
  } = props;

  const showError = useFieldError(props);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newNumberValue = e.target.value?.replaceAll(/[€ ]/g, "");

    additionalOnChange?.(newNumberValue);
    onChange?.(newNumberValue);
  };

  return (
    <StyledNumberInputField className={className}>
      {required ? (
        <RequiredLabel>{label}</RequiredLabel>
      ) : (
        <label>{label}</label>
      )}

      <NumericFormat
        value={convertToNumber(value)}
        allowNegative={false}
        allowLeadingZeros={false}
        prefix={prefix ?? DEFAULT_PREFIX}
        placeholder={placeholder ?? DEFAULT_PLACEHOLDER}
        onChange={handleChange}
        inputMode="numeric"
        {...rest}
        decimalSeparator={","}
        thousandSeparator={"."}
      />
      {showError && <small className="ffc-number-input__error">{error}</small>}
      {!!description && !showError && <small>{description}</small>}
    </StyledNumberInputField>
  );
};

export default NumberInputField;

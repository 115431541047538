import { useUtilities } from "@faxi/web-component-library";
import { Form } from "@faxi/web-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import isEqual from "lodash.isequal";
import { FC } from "react";

import { API_PATHS } from "common";
import { Loader } from "components";
import { MutationMethod, MutationVariables } from "types";
import { convertToNumber } from "utils";

import { useAuthenticateUser } from "../../../utils/cognitoHostedAuth";

import { StyledCalculator } from "./LoanRates.styled";
import { LoanRatesTable } from "./LoanRatesTable.component";

type LoanRatesResponse = Record<string, string>;

const LoanRates: FC = () => {
  const { userCredentials } = useAuthenticateUser();
  const queryClient = useQueryClient();

  const loanRatesQueryKey = [
    API_PATHS.APR_TABLE,
    "",
    { token: userCredentials.id_token },
  ];
  const { data, isLoading } = useQuery<LoanRatesResponse>({
    queryKey: loanRatesQueryKey,
  });

  const { showSnackBar, prompts } = useUtilities();

  const { mutate: saveLoanRates, isPending } = useMutation<
    LoanRatesResponse,
    Error,
    MutationVariables
  >({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: loanRatesQueryKey });
      showSnackBar({
        text: "Successfully updated loan rates data",
        variant: "success",
      });
    },
    onError: (err) => {
      const defaultErrorMessage =
        "Oops! Something went wrong, new data could not be submitted.";

      showSnackBar({
        text: err.message || defaultErrorMessage,
        variant: "error",
      });
    },
  });

  const onSubmit = async (formData: Record<string, string>) => {
    if (isEqual(formData, data)) return;

    const isSubmissionConfirmed = await prompts.standard({
      title: "Confirm Submission",
      content: "Are you sure you want to submit the new loan rates?",
      submitBtnText: "Submit",
      cancelBtnText: "Cancel",
      position: "center",
    });

    if (isSubmissionConfirmed) {
      const entries = Object.entries(formData);

      if (entries.length > 0) {
        const finalBody = Object.fromEntries(
          entries.map(([key, value]) => [
            key,
            convertToNumber(value as string),
          ]),
        );

        saveLoanRates({
          path: API_PATHS.APR_TABLE,
          method: MutationMethod.PUT,
          body: finalBody,
          options: { token: userCredentials.id_token },
        });
      }
    }
  };

  return (
    <StyledCalculator>
      <Form onSubmit={onSubmit} initialData={data}>
        {isPending || !data || isLoading ? (
          <Loader className="table-loader" isRedirecting={false} />
        ) : (
          <LoanRatesTable title="Rates" data={data} />
        )}
      </Form>
    </StyledCalculator>
  );
};

export default LoanRates;

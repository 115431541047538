import { FormField } from "@faxi/web-form";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { NumberInputField } from "components";

const CellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  p {
    text-align: end;
  }
`;

const EditableCell: React.FC<{
  isEditable: boolean;
  value: number | string;
  prefix?: string;
  name: string;
  formatter?: (value: number | string) => ReactNode;
}> = ({ isEditable, value, prefix, formatter, name }) => (
  <td>
    <CellContainer>
      {prefix}{" "}
      {isEditable ? (
        <FormField
          name={name}
          component={NumberInputField}
          prefix=" "
          placeholder=" "
        />
      ) : (
        <p>{formatter?.(value) ?? value}</p>
      )}
    </CellContainer>
  </td>
);

export default EditableCell;

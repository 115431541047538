import { FC, ReactNode } from "react";

import { StyledRequiredLabel } from "./RequiredLabel.styled";

export enum LabelPosition {
  LEFT = "left",
  RIGHT = "right",
}

export type RequiredLabelProps = {
  children: ReactNode;
  labelPosition?: LabelPosition;
  className?: string;
};

const RequiredLabel: FC<RequiredLabelProps> = ({
  children,
  className,
  labelPosition = LabelPosition.LEFT,
}: RequiredLabelProps) => (
  <StyledRequiredLabel className={className}>
    {labelPosition === LabelPosition.LEFT && children}
    <span>*</span>
    {labelPosition === LabelPosition.RIGHT && children}
  </StyledRequiredLabel>
);

export default RequiredLabel;

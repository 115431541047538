import styled from "styled-components";

import { theme } from "@faxi/web-component-library";
import { VehicleStepHeader } from "components";

const StyledStep42MonthlyCommitments = styled(VehicleStepHeader)`
  .field-modal-label img {
    margin-bottom: ${theme.sizes.SIZE_20};
  }

  .wcl-overlay .wcl-modal {
    &__header {
      padding: ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_24} 0;
    }

    &__main {
      padding: 0 ${theme.sizes.SIZE_40};
    }
  }
`;

const StyledList = styled.ul`
  padding: 0;
  margin: 0;

  li {
    margin: ${theme.sizes.SIZE_8} 0;
  }

  li:first-of-type {
    margin-top: 0;
  }
`;

export { StyledList, StyledStep42MonthlyCommitments };

export type Vehicle = {
  id: string;
  title: string;
  make: string;
  model: string;
  trim: string;
  thumbnail: string;
  price: number;
  priceFormatted: string;
  doorCount: string;
  colour: string;
  licencePlate: string;
};

export enum Status {
  LivingTogether = "living_together",
  LivingTogetherWithChildren = "living_together_with_children",
  Single = "single",
  SingleWithChildren = "single_with_children",
}

export enum LivingSituation {
  Rent = "rent",
  Homeowners = "homeowners",
  Residential = "residential",
}

export enum IncomeType {
  EmployedUnderContract = "employed_under_contract",
  PensionAow = "pension_aow",
  SocialSecurity = "social_security",
  Other = "other",
}

export interface ManualInputs {
  maritalStatus: Status;
  livingSituation: LivingSituation;
  incomeType: IncomeType;
  incomeNet: number;
  grossHousingCost: number;
  rentalAllowance: number;
  additionalDebt: number;
  childcareCosts: number;
  carPrice: number;
  deposit: number;
  childcareAllowance: number;
  numOfChildren: number;
}

export enum MutationMethod {
  PUT = "PUT",
  POST = "POST",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export type MutationVariables = {
  path: string;
  method: MutationMethod;
  body?: Record<string, unknown>;
  options?: Record<string, unknown>;
};

export type LoanApprovalSingleResponse = Partial<{
  currency: string;
  loanAmount: number;
  loanTerm: number;
  monthlyPayment: number;
  status: LoanAmountStatus;
  rate: number;
  totalRepayable: number;
  deposit: number;
  purchasePrice: number;
}>;

export type LoanApprovalResponse = {
  askingAmount: LoanApprovalSingleResponse;
  fullAmount?: LoanApprovalSingleResponse;
};

export enum LoanAmountStatus {
  APPROVED = "approved",
  PARTIALLY_APPROVED = "partially_approved",
  REJECTED = "rejected",
  ERROR = "error",
}

export type SaveDataResponse = {
  referenceNumber?: string;
  personId: string;
};

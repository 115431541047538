import { FC } from "react";

import { Vehicle } from "types";

import { BaseCard } from "components/atoms";
import { useTranslation } from "react-i18next";
import {
  StyledBoldText,
  StyledMakeText,
  StyledVehicleDetails,
  StyledVehicleTextInfo,
} from "./VehicleDetails.styled";

export type VehicleDetailsProps = {
  vehicle: Vehicle;
};

const VehicleDetails: FC<VehicleDetailsProps> = ({ vehicle }) => {
  const { t } = useTranslation();

  return (
    <StyledVehicleDetails>
      <p>{t("startloan_title")}</p>
      <BaseCard variant={1}>
        <img src={vehicle.thumbnail} alt="Vechicle Details" loading="eager" />

        <StyledVehicleTextInfo>
          <StyledBoldText>{vehicle.make}</StyledBoldText>
          <StyledMakeText>{vehicle.trim}</StyledMakeText>
          <StyledBoldText>{`${vehicle.priceFormatted},-`}</StyledBoldText>
        </StyledVehicleTextInfo>
      </BaseCard>
    </StyledVehicleDetails>
  );
};

export default VehicleDetails;

import { pxToRem } from "@faxi/web-css-utilities";
import styled from "styled-components";

import { BaseCardProps } from "./BaseCard.component";

const StyledBaseCard = styled.div<BaseCardProps>`
  background-color: var(--WHITE);
  border-radius: ${pxToRem("12px")};

  border: ${(props) =>
    props.variant === 1 ? "1px solid var(--ACCENT_1_2)" : "none"};

  box-shadow: ${(props) =>
    props.variant === 1 ? "0px 0px 10px 3px var(--SHADOW-COLOR)" : "none"};
`;

export { StyledBaseCard };

import { flex, fontSize, pxToRem } from "@faxi/web-css-utilities";
import styled from "styled-components";

import { theme } from "@faxi/web-component-library";
import { BaseCard } from "components";

const StyledHomePage = styled.main`
  background-color: var(--GRAY-EF);

  .element-max-width {
    padding: ${theme.sizes.SIZE_16} 0 0;
  }

  .ffc-home-page {
    &__bottom-description {
      color: var(--TEXT_PRIMARY);
      text-align: justify;
      ${fontSize(theme.fontSizes.FONT_14)};
      padding: 0 ${pxToRem("10px")};
    }
  }
`;

const StyledPreApprovedCard = styled(BaseCard)`
  ${flex("column")};

  h2 {
    margin: 0;
    line-height: 1.4;
  }

  > div {
    ${flex("column")};
    gap: ${theme.sizes.SIZE_12};

    padding: ${pxToRem("20px")};

    strong {
      ${fontSize("20px")};
    }
  }

  > img {
    object-fit: cover;

    border-bottom-left-radius: ${pxToRem("12px")};
    border-bottom-right-radius: ${pxToRem("12px")};
  }
`;

const StyledDetailsCard = styled(BaseCard)`
  ${flex("column")};
  width: 100%;

  * {
    text-align: start;
    width: 100%;
  }

  gap: ${pxToRem("8px")};
  padding: ${pxToRem("16px")};
`;

export { StyledDetailsCard, StyledHomePage, StyledPreApprovedCard };

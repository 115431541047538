import { theme } from "@faxi/web-component-library";
import styled from "styled-components";

import { Header } from "components";

const StyledCalculator = styled(Header)`
  th,
  td {
    p {
      padding: ${theme.sizes.SIZE_12} 0;
    }
  }

  td {
    input {
      min-width: ${theme.sizes.SIZE_72};
      padding: ${theme.sizes.SIZE_8};
    }
  }
`;

export { StyledCalculator };

import React from "react";

import classNames from "classnames";
import { StyledEmailUs } from "./EmailUs.styled";

export type EmailUsProps = {
  href: string;
  mailText: string;
  className?: string;
};

const EmailUs: React.FC<EmailUsProps> = ({ href, mailText, className }) => (
  <StyledEmailUs
    href={href}
    className={classNames("link-with-icon", className)}
  >
    {mailText}
    <img src="/icons/mailto.svg" alt="Mailto icon" />
  </StyledEmailUs>
);

export default EmailUs;

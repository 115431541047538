import classNames from "classnames";
import React, { ReactNode } from "react";

import { StyledPreApprovedCard } from "Router/steps/Home/Home.styled";
import { BaseHeader, Loader, VehicleDetails } from "components";
import { useVehicle } from "providers";

import { StyledVehicleStepHeader } from "./VehicleStepHeader.styled";

export type VehicleStepHeaderProps = {
  children?: ReactNode;
  className?: string;
  title?: ReactNode;
};

const VehicleStepHeader: React.FC<VehicleStepHeaderProps> = ({
  children,
  className,
  title,
}) => {
  const { data, isLoading } = useVehicle();

  if (isLoading) return <Loader />;

  return (
    <StyledVehicleStepHeader
      className={classNames("fc-vehicle-header", className, {
        "fc-vehicle-header--no-data": !data,
        "fc-vehicle-header--title": title,
      })}
    >
      <section>
        <BaseHeader />
        {data ? (
          <VehicleDetails vehicle={data} />
        ) : (
          title && (
            <div className="subtitle">
              <StyledPreApprovedCard variant={1}>
                <p>{title}</p>
              </StyledPreApprovedCard>
            </div>
          )
        )}
        {children}
      </section>
    </StyledVehicleStepHeader>
  );
};

export default VehicleStepHeader;

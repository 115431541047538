import React, { ReactNode } from "react";
import { StyledTabs, Tab } from "./Tabs.styled";

export type TabsProps = {
  tabs: ReactNode[];
  activeTab: number;
  onTabClick: (index: number) => void;
  children?: React.ReactNode;
};

const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTab,
  onTabClick,
  children,
}) => (
  <StyledTabs>
    <ul>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          className={index === activeTab ? "active" : ""}
          onClick={() => onTabClick(index)}
        >
          {tab}
        </Tab>
      ))}
    </ul>
    {children}
  </StyledTabs>
);

export default Tabs;

import { StorageKeys } from "common";
import { storageService } from "services";

/**
 * Clears form fields from local storage based on the provided keys.
 *
 * @param keys - An array of keys representing the form fields to be cleared.
 */
export const clearFormFields = (keys: string[]): void => {
  const currentFormValues = storageService.getItem<Record<string, string>>(
    StorageKeys.FORM_STORAGE_KEY,
  );

  if (!currentFormValues) return;

  keys.forEach((key) => delete currentFormValues[key]);

  storageService.setItem(StorageKeys.FORM_STORAGE_KEY, currentFormValues);
};

import { theme } from "@faxi/web-component-library";
import { flex, fontSize, tablet } from "@faxi/web-css-utilities";
import { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    --PRIMARY_1_1: #ff0022;
    --PRIMARY_1_2: #cc001b;
    --PRIMARY_2_1: #cc001b;
    --PRIMARY_2_4: #ff0022;
    --PRIMARY_EB: #EB0A1E;

    --TEXT_PRIMARY: #222a33;
    --TEXT_SECONDARY: #656a71;

    --ACCENT_1_1: #656A71;
    --ACCENT_1_2: #d0d0d0;
    --ACCENT_2_1: #656a71;
    --ACCENT_1_6: #fff;

    --ALERT_SUCCESS_DARK_1_1: #068C3C;

    --WHITE: #fff;
    --BLACK: #000;

    --GRAY-F1: #f1f1f1;
    --GRAY-FB: #fbfbfb;
    --GRAY-EF: #eff0f0;
    --GRAY-65: #656A71;
    --GRAY-90: #909499;
    --GRAY-22: #222A33;
    --GRAY-CD: #cdd0d2;
    --GRAY-CA: #cacaca;
    --GRAY-DB: #dbdcdd;
    --GRAY-28: #282830;
    --GRAY-6C: #6c7073;

    --SHADOW-COLOR: #0000001f;

    background-color: var(--GRAY-FB);
  }

  main {
    text-align: center;
    padding: 162px 16px 100px;
    min-height: 100vh;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 20px;

    margin: 64px auto 80px;
    
    > * {
      width: 100%;
    }

    > form > * {
      width: 100%;
      max-width: 436px;
      margin: auto;
      padding: 0 ${theme.sizes.SIZE_16};
    }
  }

  label.wcl-radio-group__radio-button:focus-visible {
    outline: 1.5px solid var(--GRAY-65);
    outline-offset: 6px;
    border-radius: 1px;
  }

  .wcl-radio-button svg {
    height: 20px;
  }

  .wcl-radio-button__input:checked + svg .outer {
    stroke: black !important;
  }

  .wcl-radio-button__input:checked + svg .inner {
    fill: black !important;
  }

  .wcl-radio-group__label {
    color: black !important;
  }

  .button--primary--disabled {
    background-color: var(--GRAY-EF) !important;
    color: var(--GRAY-65) !important;
  }

  .button--outline {
    color: black !important;
  }

  .button--outline::after {
    border-color: black !important;
  }

  body .App .wcl-radio-group__label {
    font-size: 0.875rem;
  }

  body .App .input__container__input {
    background-color: white;
    
    &:hover {
      background-color: white;
    };
  }

  body .App .input__label {
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: ${theme.sizes.SIZE_8};
  }

  body .App .input__label__required {
    color: var(--PRIMARY_1_1);
    font-weight: 600;
  }

    body .App .fc-step-5 .input__label__required {
    color: var(--SHADE_1_1);
  }

  body .App .wcl-overlay { 
    z-index: 10;
    background-color: rgba(34, 42, 51, 0.5);

    .wcl-modal {
      border-radius: 0;
      margin: auto;
      max-width: ${theme.sizes.SIZE_392};

      &__header {
        margin-bottom: 0;
        
        &__title {
          color: var(--GRAY-22);
        }
      }

      &__main {
        color: var(--TEXT_PRIMARY);
        white-space: pre-line;
      }

      &__footer {
        ${flex("row", "flex-end", "center")};
        padding-top: ${theme.sizes.SIZE_8};

        > button {
          width: ${theme.sizes.SIZE_164};
          border-radius: 999px;
        }
      }
    }
  }

  body .wcl-modal {
    .prompt-modal__content {
      padding: 0;
      margin-top: ${theme.sizes.SIZE_16};
      color: var(--TEXT_SECONDARY);
    }

    .wcl-modal__header__title {
      color: var(--TEXT_PRIMARY);
    }

    .wcl-modal__footer  :not(:first-child) {
      margin-left: ${theme.sizes.SIZE_32};
    }
  }

  body .App .wcl-dropdown {
    &__content {
      top: 48px !important;
      border-radius: 0;
      box-shadow: none !important;
      border: none !important;
    }

    .input__container__input {
      padding: 0.75rem 1rem !important; 
    }

    .input__container__input--has-placeholder:not(:placeholder-shown) + .input__container__placeholder {
      display: none;
    }

    .cUrisw .input__container__input--has-placeholder:focus + .input__container__placeholder {
      top: 0.9rem;
      font-size: 1rem;
      color: black;
    }

    .input__container__placeholder {
      top: 0.9rem;
    }

    svg {
      color: black;
    }
  }

  body .App .wcl-checkbox {
    &__label {
      ${fontSize("14px")};
      font-weight: 500;
    }

    span.wcl-checkbox__box {
      box-shadow: none;

      height: 1.5rem;
      width: 1.5rem;

      background-color: white;
      border-color: var(--ACCENT_1_1);
      align-self: baseline;
      
      &--checked {
        background-color: black;
        border-color: black;

        svg {
          color: white;
        }
      }
    }
  }

  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    * {
      width: 100%;
    }
  }

  table input {
    width: 80px;
  }

  table thead tr {
    height: ${theme.sizes.SIZE_48};
  }

  .element-max-width {
    width: 100%;
    max-width: 436px;
    margin: auto;
    padding: 0 ${theme.sizes.SIZE_16};
  }

  .link-with-icon {
    ${flex("row", "flex-start", "center")};
    gap: ${theme.sizes.SIZE_8};

    color: var(--PRIMARY_1_1);
    font-weight: 600;
  }

  body .App form .fc-table-header-actions {
    ${flex("row", "space-between", "center")};
    gap: ${theme.sizes.SIZE_16};
    width: 100%;

    &__text {
      ${flex("row", "flex-start", "center")};
      gap: ${theme.sizes.SIZE_8};

      span:nth-of-type(2) {
        color: gray;
      }
    }

    &__group {
      ${flex("row", "space-between", "center")};
      gap: ${theme.sizes.SIZE_8};

      ${tablet(css`
        ${flex("column", "flex-start", "center")};

        button {
          width: 100%;
        }
      `)}
    }

    .fc-admin-outlined-button {
      background-color: white;
      color: var(--TEXT_PRIMARY);
      
      &::after{
        border-color: var(--GRAY-90);
      }

      &:hover {
        background-color: var(--GRAY-F1);

        &::after {
          border-color: var(--GRAY-90);
        }
      }
    }

    button {
      min-height: ${theme.sizes.SIZE_40};
      max-height: ${theme.sizes.SIZE_40};

      &:hover {
        &::after {
          border-color: var(--PRIMARY_1_1);
        }
      }

      &:disabled {
        &::after {
          border-color: var(--GRAY-EF);
        }
      }

    }
  }
  
  .table-loader {
    position: relative;
    height: 50vh;
  }

  .wcl-modal .wcl-modal__header {
    padding: ${theme.sizes.SIZE_8};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--GRAY-22) !important;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: ${theme.sizes.SIZE_16};
    margin-bottom: ${theme.sizes.SIZE_32};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  th,
  td {
    padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
    text-align: left;
    border-bottom: 1px solid #e0e0e0;

    p {
      padding: ${theme.sizes.SIZE_12} 0;
    }
  }

  th {
    background-color: #f5f5f5;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }

  td {
    color: #333;

    input {
      min-width: ${theme.sizes.SIZE_64};
      padding: ${theme.sizes.SIZE_8};
    }
  }

  body .prompt-modal {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

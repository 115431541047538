import { theme } from "@faxi/web-component-library";
import { fontSize, pxToRem } from "@faxi/web-css-utilities";
import styled from "styled-components";

const StyledNumberInputField = styled.div`
  .ffc-number-input__error {
    color: var(--ALERT_ERROR_1_1);
  }

  & input {
    ${fontSize("16px")};
    width: 100%;
    padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_16};
    border: 1px solid var(--GRAY-CD);
    border-radius: 6px;

    &:focus-within {
      outline: none;
      border-color: var(--ACCENT_1_1);
    }

    &::placeholder {
      color: var(--GRAY-90);
    }
  }

  p,
  label {
    ${fontSize("14px")};

    color: var(--SHADE_1_1);
    font-weight: 500;
    margin-bottom: 8px;
  }

  label,
  small {
    display: flex;
    width: 100%;
    text-align: start;
  }

  small {
    color: var(--TEXT_SECONDARY);
    margin-top: ${pxToRem("8px")};
  }
`;

export { StyledNumberInputField };

import { FormProps, FormRef } from "@faxi/web-form";
import { forwardRef } from "react";

import { StyledFormStep } from "./FormStep.styled";

const FormStep = forwardRef<FormRef, FormProps>((props, ref) => (
  <StyledFormStep ref={ref} {...props}>
    {props.children}
  </StyledFormStep>
));

export default FormStep;

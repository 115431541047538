const toRanges = (config: Record<string, string>): Record<string, string> => {
  const MIN_LOAN_AMOUNT = 2_500;
  const MAX_LOAN_AMOUNT = 175_000;
  let threshold = MIN_LOAN_AMOUNT;

  return Object.entries(config).reduce((acc, [key, value]) => {
    const parsedKey = parseInt(key);
    const currentThreshold =
      parsedKey === MAX_LOAN_AMOUNT ? parsedKey : parsedKey - 1;
    const currentRange = {
      ...acc,
      [`${threshold}-${currentThreshold}`]: value,
    };
    threshold = parsedKey;

    return currentRange;
  }, {});
};

const keysToLocale = (
  config: Record<string, string>,
  locale: string,
  options?: Record<string, string>,
): Record<string, string> => {
  return Object.entries(config).reduce((acc, [key, value]) => {
    const [lower, higher] = key.split("-");
    const parsedLower = parseInt(lower);
    const parsedHigher = parseInt(higher);

    const formattedLower = parsedLower.toLocaleString(locale, options);
    const formattedHigher = parsedHigher.toLocaleString(locale, options);

    return {
      ...acc,
      [`${formattedLower}-${formattedHigher}`]: value,
    };
  }, {});
};

export { toRanges, keysToLocale };

import React, { createContext, FC, useContext } from "react";
import { getRedirectUrls } from "./config";
import { CognitoConfig, UserCredentialsState } from "./interfaces";
import { useAuthenticate } from "./useAuthenticate";

interface AuthenticateContextData {
  isAuthenticated: boolean;
  userCredentials: UserCredentialsState;
  fromLocation: string | null;
  setFromLocation: (location: string) => void;
  loginRedirectUrl: string;
  logoutRedirectUrl: string;
  login: (code: string, redirectPath: string | undefined) => Promise<void>;
  logout: () => void;
  isLoading: boolean;
}

const AuthenticateContext = createContext<AuthenticateContextData>({
  isAuthenticated: false,
  userCredentials: {
    id_token: null,
    access_token: null,
    refresh_token: null,
    expires_in: null,
    token_type: null,
  },
  fromLocation: null,
  loginRedirectUrl: "",
  logoutRedirectUrl: "",
  setFromLocation: () => {},
  logout: () => {},
  login: () => Promise.resolve(),
  isLoading: false,
});

type AuthenticateProviderProps = {
  children: React.ReactNode;
  config: CognitoConfig;
};

const AuthenticateProvider: FC<AuthenticateProviderProps> = ({
  children,
  config,
}) => {
  const {
    isAuthenticated,
    fromLocation,
    setFromLocation,
    userCredentials,
    login,
    logout,
    isLoading,
  } = useAuthenticate(config);

  const { loginRedirectUrl, logoutRedirectUrl } = getRedirectUrls(config);

  return (
    <AuthenticateContext.Provider
      value={{
        isAuthenticated,
        userCredentials,
        fromLocation,
        setFromLocation,
        loginRedirectUrl,
        logoutRedirectUrl,
        login,
        logout,
        isLoading,
      }}
    >
      {children}
    </AuthenticateContext.Provider>
  );
};

const useAuthenticateUser = () => useContext(AuthenticateContext);

export { AuthenticateProvider, useAuthenticateUser };

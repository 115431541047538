import React, { ReactNode } from "react";

import {
  StyledDetailsInfo,
  StyledKeyText,
  StyledValueText,
} from "./DetailsInfo.styled";

export type DetailsInfoProps = {
  title: string;
  value: ReactNode;
};

const DetailsInfo: React.FC<DetailsInfoProps> = ({ title, value }) => (
  <StyledDetailsInfo>
    <StyledKeyText>{title}</StyledKeyText>
    <StyledValueText>{value}</StyledValueText>
  </StyledDetailsInfo>
);

export default DetailsInfo;

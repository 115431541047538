import { AnalyticsBrowser } from "@segment/analytics-next";
import React, { ReactNode, useContext } from "react";
import AnalyticsContext from "./Analytics.context";
import AnalyticsSingleton from "./analytics-instance";

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const analytics = AnalyticsSingleton.getInstance();

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): AnalyticsBrowser => {
  const context = useContext(AnalyticsContext);
  if (context === null) {
    throw new Error(
      "useAnalytics must be used within an AnalyticsProvider component",
    );
  }
  return context;
};

import { TFunction } from "i18next";
import { parseToNumber } from "utils";

export const validations = (t: TFunction<"translation", undefined>) => ({
  positiveNumber: [
    (value: string) =>
      !value || parseToNumber(value) <= 0 ? t("error_positive_number") : "",
  ],
  positiveNumberIncludesZero: [
    (value: string) =>
      !value || parseToNumber(value) < 0
        ? t("error_positive_number_includes_zero")
        : "",
  ],
  selectAnyValue: [
    (value: string) => (!value ? t("error_select_any_value") : ""),
  ],
  betweenOneAndNine: [
    (value: string) =>
      !value || parseToNumber(value) < 1 || parseToNumber(value) > 9
        ? t("error_enter_number_between", { min: 1, max: 9 })
        : "",
  ],
});

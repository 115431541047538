import { FC, useEffect, useRef } from "react";

import { APP_URI } from "common";
import { Loader } from "components";
import { useURLSearchParams } from "hooks";

import { useAuthenticateUser } from "./AuthenticateUserProvider";
import { useHistory } from "react-router-dom";

const Credentials: FC = () => {
  const { login, isAuthenticated } = useAuthenticateUser();
  const history = useHistory();
  const existingCode = useRef("");

  const {
    params: { code },
  } = useURLSearchParams();

  useEffect(() => {
    if (!isAuthenticated && code && existingCode.current !== code) {
      existingCode.current = code;
      login(code, APP_URI.ADMIN_CONFIG);
    }
    if (isAuthenticated) {
      history.replace(APP_URI.ADMIN_CONFIG);
    }
  }, [code, login, isAuthenticated, history]);

  return <Loader isRedirecting={false} />;
};

export default Credentials;

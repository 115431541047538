import { FC, ReactNode } from "react";
import FooterLinks from "components/molecules/FooterLinks";

import { StyledFooter } from "./Footer.styled";

interface FooterProps {
  children?: ReactNode;
}

const Footer: FC<FooterProps> = ({ children }: FooterProps) => (
  <StyledFooter className="ffc-footer">
    <FooterLinks />
    {children}
  </StyledFooter>
);

export default Footer;

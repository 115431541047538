import { flex, pxToRem } from "@faxi/web-css-utilities";
import { Form } from "@faxi/web-form";
import styled from "styled-components";

const StyledFormStep = styled(Form)`
  ${flex("column")};
  gap: ${pxToRem("20px")};
  margin-top: 0;

  > button {
    margin: auto;
    width: 100%;
  }
`;

export { StyledFormStep };

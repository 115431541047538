import {
  Button,
  RadioGroup,
  useCallbackRef,
} from "@faxi/web-component-library";
import { FormField, FormRef, useFormRefValues } from "@faxi/web-form";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import {
  APP_URI,
  DEPENDENT_FIELDS,
  FormSteps,
  StorageKeys,
  YesNo,
  getYesNoOptions,
} from "common";
import {
  FormActions,
  FormStep,
  NumberInputField,
  RequiredLabel,
} from "components";
import { storageService } from "services";
import { clearFormFields, validations } from "utils";

import { useAnalytics } from "providers";
import { useTranslation } from "react-i18next";
import { StyledStep43Resident } from "./Step43Resident.styled";

export type Step43ResidentFormValue = {
  doYouPayMonthlyPaymentsTowardsResidency: YesNo;
  grossHousingCost: string;
};
export type Step43ResidentProps = {};

const onDoYouPayMonthlyPaymentsChange = (doYouPayMonthlyPayments: string) => {
  if (doYouPayMonthlyPayments === YesNo.NO)
    clearFormFields(DEPENDENT_FIELDS.doYouPayMonthlyPaymentsTowardsResidency);
};

const isValid = (formValues: Step43ResidentFormValue) => {
  const { doYouPayMonthlyPaymentsTowardsResidency, grossHousingCost } =
    formValues;

  return (
    doYouPayMonthlyPaymentsTowardsResidency === YesNo.NO ||
    (grossHousingCost && +grossHousingCost > 0)
  );
};

const Step43Resident: React.FC<Step43ResidentProps> = () => {
  const [form, formRef] = useCallbackRef<FormRef>();
  const history = useHistory();
  const analytics = useAnalytics();

  const { t } = useTranslation();

  const handleTrackEvent = useCallback(() => {
    analytics.track("Next Step", {
      stepCompleted: history.location.pathname,
    });
  }, [history.location?.pathname, analytics]);

  const handleSubmit = useCallback(async () => {
    storageService.setItem(
      StorageKeys.COMPLETED_STEPS_STORAGE_KEY,
      FormSteps.STEP_4_2,
    );
    handleTrackEvent();

    history.push(APP_URI.STEP_4_MONTHLY_COMMITMENTS);
  }, [history, handleTrackEvent]);

  const doYouPayMonthlyPaymentsTowardsResidency = useFormRefValues(
    form,
    "doYouPayMonthlyPaymentsTowardsResidency",
  );

  const grossHousingCost = useFormRefValues(form, "grossHousingCost");

  const formValues = useMemo(
    () =>
      ({
        ...grossHousingCost,
        ...doYouPayMonthlyPaymentsTowardsResidency,
      }) as Step43ResidentFormValue,
    [grossHousingCost, doYouPayMonthlyPaymentsTowardsResidency],
  );

  const shouldRenderHowMuchPerMonth =
    formValues?.doYouPayMonthlyPaymentsTowardsResidency === YesNo.YES;

  return (
    <StyledStep43Resident>
      <FormStep ref={formRef} onSubmit={handleSubmit}>
        <FormField
          name="doYouPayMonthlyPaymentsTowardsResidency"
          options={getYesNoOptions(t)}
          component={RadioGroup}
          orientation="column"
          onChange={onDoYouPayMonthlyPaymentsChange}
          label={
            <RequiredLabel>
              {t("do_you_pay_any_monthly_payments_towards_residency")}
            </RequiredLabel>
          }
        />

        {shouldRenderHowMuchPerMonth && (
          <FormField
            name="grossHousingCost"
            label={t("materialstatus_question_childcare_howmuch")}
            required
            validate={validations(t).positiveNumber}
            component={NumberInputField}
          />
        )}
        <FormActions hasBackButton>
          <Button type="submit" disabled={!isValid(formValues)}>
            {t("next")}
          </Button>
        </FormActions>
      </FormStep>
    </StyledStep43Resident>
  );
};

export default Step43Resident;

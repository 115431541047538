import { theme } from "@faxi/web-component-library";
import { flex } from "@faxi/web-css-utilities";
import styled from "styled-components";

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  ${flex("row", "space-between")};
  border-bottom: 1px solid var(--GRAY-CA);
  padding: ${theme.sizes.SIZE_12} 0;
  color: var(--TEXT_PRIMARY);
`;

const EmphasizedValue = styled.span<{ hasPrimaryColor?: boolean }>`
  color: ${({ hasPrimaryColor }) =>
    hasPrimaryColor ? "var(--PRIMARY_1_1)" : "inherit"};
`;

export { EmphasizedValue, List, ListItem };

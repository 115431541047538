import styled from "styled-components";

const StyledSelectField = styled.div`
  div {
    cursor: pointer;
  }

  .kinto-select-field {
    &__label {
      color: var(--TEXT_PRIMARY);
      margin: 0;
      line-height: 1.3rem;

      margin-bottom: 12px;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }

  .error-text {
    color: var(--ALERT_ERROR_1_1);
  }
`;

export { StyledSelectField };

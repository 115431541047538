import { theme } from "@faxi/web-component-library";
import styled from "styled-components";

const StyledCheckboxField = styled.div`
  small {
    display: flex;
    margin-top: ${theme.sizes.SIZE_12};
    color: var(--ALERT_ERROR_1_1);
  }
`;

export { StyledCheckboxField };

import { APP_URI } from "common";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step21SingleWithChildren from "./Step2-1-SingleWithChildren";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step42MonthlyCommitments from "./Step4-2-MonthlyCommitments";
import Step43Resident from "./Step4-3-Resident";
import Step5PersonalDetails from "./Step5-PersonalDetails";

export type StepRoute = Route;

export const FORM_STEPS = [
  {
    handle: "step1",
    path: APP_URI.STEP_1,
    exact: true,
    component: Step1,
  },
  {
    handle: "step2-1",
    path: APP_URI.STEP_2,
    exact: true,
    component: Step2,
  },
  {
    handle: "step2-single-with-children",
    path: APP_URI.STEP_2_SINGLE_WITH_CHILDREN,
    exact: true,
    component: Step21SingleWithChildren,
  },
  {
    handle: "step3-1",
    path: APP_URI.STEP_3,
    exact: true,
    component: Step3,
  },
  {
    handle: "step4-1",
    path: APP_URI.STEP_4,
    exact: true,
    component: Step4,
  },
  {
    handle: "step4-resident",
    path: APP_URI.STEP_4_RESIDENT,
    exact: true,
    component: Step43Resident,
  },
  {
    handle: "step4-monthly-commitments",
    path: APP_URI.STEP_4_MONTHLY_COMMITMENTS,
    exact: true,
    component: Step42MonthlyCommitments,
  },
  {
    handle: "step5-1-personal-details",
    path: APP_URI.STEP_5,
    exact: true,
    component: Step5PersonalDetails,
  },
];

export interface Route {
  handle: string;
  path: string;
  exact?: boolean;
  component: React.FC | any;
  routes?: Route[];
}

import { useState } from "react";
import { useHistory } from "react-router-dom";

import { getUserCredentialsData } from "./config";
import { CognitoConfig, UserCredentialsState } from "./interfaces";
import { validateUserCredentials } from "./validateUserCredentials";
import { storageService } from "services";

const useAuthenticate = (config: CognitoConfig) => {
  const existingCreds =
    storageService.getItem<UserCredentialsState>("userCredentials");

  const [isAuthenticated, setIsAuthenticated] =
    useState<boolean>(!!existingCreds);
  const [userCredentials, setUserCredentials] = useState<UserCredentialsState>(
    existingCreds || {
      id_token: null,
      access_token: null,
      refresh_token: null,
      expires_in: null,
      token_type: null,
    },
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fromLocation, setFromLocation] = useState<string | null>(null);
  const history = useHistory();

  const login = async (
    code: string,
    redirectPath: string | undefined,
  ): Promise<void> => {
    setIsLoading(true);
    const { body, getUserCredentialsUrl } =
      getUserCredentialsData(config)(code);

    return fetch(getUserCredentialsUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body,
    })
      .then((response) => response.json())
      .then((data) => {
        if (!validateUserCredentials(data)) {
          throw new Error("Invalid user credentials data");
        }
        setIsAuthenticated(true);
        setUserCredentials(data);

        if (data?.id_token) {
          storageService.setItem("userCredentials", data);
          history.replace(redirectPath || "/");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => setIsLoading(false));
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserCredentials({
      id_token: null,
      access_token: null,
      refresh_token: null,
      expires_in: null,
      token_type: null,
    });
    history.push("/login");
  };

  return {
    isAuthenticated,
    fromLocation,
    setFromLocation,
    userCredentials,
    login,
    logout,
    isLoading,
  };
};

export { useAuthenticate };

import React from "react";

import {
  IconRow,
  IconRowWrapper,
  LeftIcon,
  RightIcon,
  Section,
  StyledHeaderBranding,
} from "./HeaderBranding.styled";
import LanguageSelector from "../LanguageSelector";

export type HeaderBrandingProps = {};

const HeaderBranding: React.FC<HeaderBrandingProps> = () => {
  return (
    <StyledHeaderBranding>
      <Section>
        <IconRowWrapper>
          <IconRow>
            <LeftIcon src="/icons/car-match.svg" alt="Car Match Icon" />
            <RightIcon
              src="/icons/logo-with-text.svg"
              alt="Logo with Text Icon"
            />
            <LanguageSelector />
          </IconRow>
        </IconRowWrapper>
      </Section>
    </StyledHeaderBranding>
  );
};

export default HeaderBranding;

import { pxToRem } from "@faxi/web-css-utilities";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledButtonLink = styled(NavLink)`
  background-color: var(--PRIMARY_1_1);
  color: var(--WHITE);

  text-decoration: none;

  padding: ${pxToRem("13px")} ${pxToRem("16px")};
  font-size: ${pxToRem("15px")};

  border-radius: 999px;
  width: 100%;

  &:focus-visible {
    outline: 2px solid var(--ACCENT_1_1);
  }
`;

export { StyledButtonLink };

import { QueryFunction } from "@tanstack/react-query";

import env from "env";

import { encodePath } from ".";
import { getApiUrl } from "./url";
import { fetchWithInterceptor } from "./fetchWithInterceptor";

export const BASE_PATH = env.VITE_API_KEY || getApiUrl();

export const defaultQueryFn: QueryFunction = async ({ queryKey }) => {
  const [queryKeyPath, queryKeyParams, options] = queryKey as [
    string,
    Record<string, string>,
    Record<string, any>,
  ];

  const token = options?.token;
  const path = encodePath(queryKeyPath);
  const queryString = new URLSearchParams(queryKeyParams).toString();

  const basePath = `${BASE_PATH}${path}`;

  const url = queryString ? `${basePath}?${queryString}` : basePath;
  const authHeader: Record<string, string> = token
    ? { Authorization: `Bearer ${token}` }
    : {};

  try {
    const res = await fetchWithInterceptor(url, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader,
      },
    });

    if (!res?.ok) throw res;

    const json = await res.json();
    return json;
  } catch (err) {
    throw Error(err as string);
  }
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

export const FALLBACK_LANG = "en";
export const SUPPORTED_LANGUAGES = ["en", "nl"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/assets/kinto/locales/{{lng}}/{{ns}}.json",
    },
    detection: {
      order: [
        "querystring",
        "localStorage",
        "cookie",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      lookupQuerystring: "language",
    },
    supportedLngs: SUPPORTED_LANGUAGES,
    load: "languageOnly",
    fallbackLng: FALLBACK_LANG,
    returnEmptyString: false,
    lng: localStorage.getItem("i18nextLng") || "nl",

    ns: "translation",
    defaultNS: "translation",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

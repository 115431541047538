import { FC } from "react";

import { StorageKeys } from "common";
import { storageService } from "services";
import { LoanAmountStatus, LoanApprovalResponse } from "types";

import { useTranslation } from "react-i18next";
import { LoanApproved, LoanRejected } from "./components";

const renderLoanAmountFinalStep = (
  t: (message: string) => string,
  status?: LoanAmountStatus,
) => {
  const partiallyApprovedMessages = t("outcome_card_partialapproval").split(
    "\\n",
  );

  switch (status) {
    case LoanAmountStatus.APPROVED:
      return (
        <LoanApproved
          personalLoanOfferText={t("outcome_card_textunder")}
          postApprovedText={t("outcome_card_text_loan")}
        />
      );
    case LoanAmountStatus.PARTIALLY_APPROVED:
      return (
        <LoanApproved
          personalLoanOfferText={partiallyApprovedMessages[0]}
          preApprovedText={partiallyApprovedMessages[1]}
        />
      );
    case LoanAmountStatus.REJECTED:
      return <LoanRejected />;
    default:
      return null;
  }
};

const Result: FC = () => {
  const { askingAmount } =
    storageService.getItem<LoanApprovalResponse>(
      StorageKeys.LOAN_AMOUNT_RESULT_STORAGE_KEY,
    ) || {};

  const { status } = askingAmount || {};

  const { t } = useTranslation();

  return renderLoanAmountFinalStep(t, status);
};

export default Result;

import { FC, useMemo } from "react";
import { Select, SelectOption } from "@faxi/web-component-library";
import { useTranslation } from "react-i18next";

const LanguageSelector: FC = () => {
  const { i18n, t } = useTranslation();

  const languageOptions = useMemo<SelectOption[]>(
    () => [
      { value: "nl", label: "NL" },
      { value: "en", label: "EN" },
    ],
    [],
  );

  const selectedLanguage = useMemo(
    () => languageOptions?.find((lang) => lang.value === i18n.language),
    [i18n, languageOptions],
  );

  return (
    <Select
      options={languageOptions}
      value={selectedLanguage}
      className="ffc-language-selector"
      deleteInputAriaLabel={t("delete_input")}
      chevronBtnAriaLabel={t("accessibility-show_select_options")}
      onChange={(val) => i18n.changeLanguage(val.value)}
    />
  );
};

export default LanguageSelector;

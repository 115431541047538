import {
  flex,
  fontSize,
  mobile,
  position,
  pxToRem,
} from "@faxi/web-css-utilities";
import styled, { css } from "styled-components";

const StyledVehicleDetails = styled.div`
  ${position("absolute", "bottom auto left 0 right 0 top 165px")};
  padding: ${pxToRem("24px")} 0 ${pxToRem("24px")};

  background-color: var(--GRAY-EF);

  > * {
    margin: auto;
    max-width: 404px;
    width: 90%;
  }

  ${flex("column")}
  gap: ${pxToRem("20px")};
  margin-top: 0;

  > p {
    text-align: center;
    font-weight: 600;
  }

  > div {
    ${flex("row")};
    gap: ${pxToRem("16px")};
    padding: ${pxToRem("6px")};

    img {
      max-height: 78px;
      width: 104px;
      height: fit-content;
      align-self: center;
      object-fit: contain;
      border-radius: ${pxToRem("8px")};
    }
  }

  ${mobile(css`
    top: 153px;
  `)}
`;

const StyledVehicleTextInfo = styled.div`
  ${flex("column", "center", "flex-start")};

  p {
    ${fontSize("15px", "22px")};
    text-align: start;
  }
`;

const StyledMakeText = styled.p`
  color: var(--TEXT_SECONDARY);
  font-weight: 400;
`;

const StyledBoldText = styled.p`
  font-weight: 600;
`;

export {
  StyledBoldText,
  StyledMakeText,
  StyledVehicleDetails,
  StyledVehicleTextInfo,
};

import { theme } from "@faxi/web-component-library";
import { flex, fontSize } from "@faxi/web-css-utilities";
import styled from "styled-components";

const StyledLoanRejected = styled.div`
  &.fc-loan-rejected {
    min-height: 100vh;
    background-color: var(--GRAY-EF);
    padding: ${theme.sizes.SIZE_136} ${theme.sizes.SIZE_20};

    .fc-loan-rejected__card {
      ${flex("column", "center", "center")};
      gap: ${theme.sizes.SIZE_16};
      margin-top: ${theme.sizes.SIZE_24};

      max-width: 370px;
      background-color: white;

      border-radius: ${theme.sizes.SIZE_8};
      padding: ${`${theme.sizes.SIZE_24} ${theme.sizes.SIZE_20} ${theme.sizes.SIZE_20}`};

      h2 {
        ${fontSize("16px", "23px")};
        text-align: center;
        font-weight: 600;
        margin: 0;
        margin-bottom: ${theme.sizes.SIZE_10};
      }

      p {
        text-align: center;
      }

      button {
        width: 100%;
        border-radius: 999px;
      }

      &__reference {
        font-weight: 600;
      }
    }
  }
`;

export { StyledLoanRejected };

import {
  Button,
  RadioGroup,
  useCallbackRef,
} from "@faxi/web-component-library";
import { FormField, FormRef, useFormRefValues } from "@faxi/web-form";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import {
  APP_URI,
  DEPENDENT_FIELDS,
  FormSteps,
  StorageKeys,
  YesNo,
  getYesNoOptions,
} from "common";

import {
  FormActions,
  FormStep,
  NumberInputField,
  RequiredLabel,
} from "components";
import { storageService } from "services";
import { clearFormFields, validations } from "utils";

import { useAnalytics } from "providers";
import { useTranslation } from "react-i18next";
import { OptionModalLabel } from "../Step4/components";
import {
  StyledList,
  StyledStep42MonthlyCommitments,
} from "./Step42MonthlyCommitments.styled";

export type Step42MonthlyCommitmentsProps = {};
export type Step42MonthlyCommitmentsFormValue = {
  otherMonthlyCommitments: YesNo;
  additionalDebt: string;
};

const onOtherMonthlyCommitmentsChange = (otherMonthlyCommitments: string) => {
  if (otherMonthlyCommitments === YesNo.NO)
    clearFormFields(DEPENDENT_FIELDS.otherMonthlyCommitments);
};

const Step42MonthlyCommitments: React.FC<
  Step42MonthlyCommitmentsProps
> = () => {
  const [form, formRef] = useCallbackRef<FormRef>();
  const history = useHistory();
  const analytics = useAnalytics();

  const { t } = useTranslation();

  const handleTrackEvent = useCallback(() => {
    analytics.track("Next Step", {
      stepCompleted: history.location.pathname,
    });
  }, [history.location?.pathname, analytics]);

  const handleSubmit = useCallback(async () => {
    storageService.setItem(
      StorageKeys.COMPLETED_STEPS_STORAGE_KEY,
      FormSteps.STEP_4_2,
    );
    handleTrackEvent();

    history.push(APP_URI.STEP_5);
  }, [history, handleTrackEvent]);

  const otherMonthlyCommitments = useFormRefValues(
    form,
    "otherMonthlyCommitments",
  );
  const additionalDebt = useFormRefValues(form, "additionalDebt");

  const formValues = useMemo(
    () =>
      ({
        ...otherMonthlyCommitments,
        ...additionalDebt,
      }) as Step42MonthlyCommitmentsFormValue,
    [otherMonthlyCommitments, additionalDebt],
  );

  const shouldRenderHowMuchPerMonth =
    formValues?.otherMonthlyCommitments === YesNo.YES;

  const modalMessages = t("livinsituation_question_otherdebts_modal").split(
    "\\n\\n",
  );
  const modalHeader = modalMessages[0];
  const modalDescription = modalMessages[1].split("\\n");

  return (
    <StyledStep42MonthlyCommitments>
      <FormStep ref={formRef} onSubmit={handleSubmit}>
        <FormField
          name="otherMonthlyCommitments"
          options={getYesNoOptions(t)}
          component={RadioGroup}
          orientation="column"
          onChange={onOtherMonthlyCommitmentsChange}
          label={
            <OptionModalLabel
              label={
                <RequiredLabel>
                  {t("livinsituation_question_otherdebts")}
                </RequiredLabel>
              }
              modalProps={{
                title: modalHeader,
                description: (
                  <StyledList>
                    {modalDescription.map((message) => (
                      <li key={message}>{message}</li>
                    ))}
                  </StyledList>
                ),
              }}
              labelClassName="field-modal-label"
              labelPosition="right"
            />
          }
          validate={validations(t).selectAnyValue}
        />

        {shouldRenderHowMuchPerMonth && (
          <FormField
            name="additionalDebt"
            label={t("livinsituation_question_otherdebts_howmuch")}
            required
            validate={validations(t).positiveNumber}
            component={NumberInputField}
          />
        )}
        <FormActions hasBackButton>
          <Button type="submit" disabled={!form?.syncFormValid}>
            {t("next")}
          </Button>
        </FormActions>
      </FormStep>
    </StyledStep42MonthlyCommitments>
  );
};

export default Step42MonthlyCommitments;

import { Button } from "@faxi/web-component-library";
import { useState } from "react";

import { camelCaseToTitleCase } from "../../../utils";
import { EditableCell } from "../EditableCell";

import { keysToLocale, toRanges } from "./utils";

const LoanRatesTable = ({
  title,
  data,
}: {
  title: string;
  data: Record<string, string>;
}) => {
  const [editableTables, setEditableTables] = useState<{
    [key: string]: boolean;
  }>({});

  const isEditable = editableTables[title] || false;

  const handleEditClick = (key: string) => {
    setEditableTables((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const originalRates = Object.keys(data);
  const withRanges = toRanges(data);
  const formatted = keysToLocale(withRanges, "nl-NL");

  return (
    <div key={title}>
      <div className="fc-table-header-actions">
        <h2>{camelCaseToTitleCase(title)}</h2>
        {isEditable ? (
          <div className="fc-table-header-actions__group">
            <Button
              className="fc-admin-outlined-button"
              onClick={() => handleEditClick(title)}
            >
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </div>
        ) : (
          <Button onClick={() => handleEditClick(title)}>Edit</Button>
        )}
      </div>

      <table>
        <thead>
          <tr>
            <th>Amount</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(formatted).map(([key, value], index) => (
            <tr key={key}>
              <td>
                <p>{key}</p>
              </td>
              <EditableCell
                isEditable={isEditable}
                value={value}
                name={originalRates[index]}
              />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { LoanRatesTable };

import {
  UtilitiesProvider,
  GlobalStyle as WCLGlobalStyle,
} from "@faxi/web-component-library";

import Router from "Router";
import { GlobalStyle } from "globalStyles";
import { AnalyticsProvider, KFQueryClientProvider } from "providers";

import "./modules/i18n";

const App = () => (
  <div className="App">
    <WCLGlobalStyle />
    <GlobalStyle />

    <UtilitiesProvider>
      <KFQueryClientProvider>
        <AnalyticsProvider>
          <Router />
        </AnalyticsProvider>
      </KFQueryClientProvider>
    </UtilitiesProvider>
  </div>
);

export default App;

import { Button, useCallbackRef } from "@faxi/web-component-library";
import { FormField, FormRef, useFormRefValues } from "@faxi/web-form";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { APP_URI, FormSteps, StorageKeys } from "common";
import {
  FormActions,
  FormStep,
  NumberInputField,
  RequiredLabel,
  SelectField,
} from "components";
import { storageService } from "services";
import { IncomeType } from "types";
import { validations } from "utils";

import { useAnalytics } from "providers";
import { useTranslation } from "react-i18next";
import { OptionModalLabel } from "../Step4/components";
import { StyledStep3 } from "./Step3.styled";

export type Step3FormValue = {};
export type Step3Props = {};

const Step3: React.FC<Step3Props> = () => {
  const [form, formRef] = useCallbackRef<FormRef>();
  const history = useHistory();
  const analytics = useAnalytics();
  const { t } = useTranslation();

  const handleTrackEvent = useCallback(() => {
    analytics.track("Next Step", {
      stepCompleted: history.location.pathname,
    });
  }, [history.location?.pathname, analytics]);

  const handleSubmit = useCallback(async () => {
    storageService.setItem(
      StorageKeys.COMPLETED_STEPS_STORAGE_KEY,
      FormSteps.STEP_3,
    );
    handleTrackEvent();

    history.push(APP_URI.STEP_4);
  }, [history, handleTrackEvent]);

  const formValues = useFormRefValues(form, "incomeType");

  const shouldRenderIncomeNet = formValues?.incomeType;

  const SELECT_OPTIONS = useMemo(
    () => [
      {
        value: IncomeType.EmployedUnderContract,
        label: t("employment_question_type_permament"),
      },
      {
        value: IncomeType.PensionAow,
        label: t("employment_question_type_pension"),
      },
      {
        value: IncomeType.SocialSecurity,
        label: t("employment_question_type_payment"),
      },
      { value: IncomeType.Other, label: t("employment_question_type_other") },
    ],
    [t],
  );

  return (
    <StyledStep3>
      <FormStep ref={formRef} onSubmit={handleSubmit}>
        <div>
          <FormField
            name="incomeType"
            options={SELECT_OPTIONS}
            component={SelectField}
            placeholder={t("select_option")}
            validate={validations(t).selectAnyValue}
            label={t("employment_question_type")}
          />
        </div>

        {shouldRenderIncomeNet && (
          <div>
            <OptionModalLabel
              label={
                <RequiredLabel>
                  {t("employment_question_monthlyincome")}
                </RequiredLabel>
              }
              modalProps={{
                title: "",
                description: t(
                  "employment_question_monthlyincome_modal_income",
                ),
              }}
              labelClassName="field-modal-label"
              labelPosition="right"
            />

            <FormField
              name="incomeNet"
              validate={validations(t).positiveNumber}
              component={NumberInputField}
            />
          </div>
        )}
        <FormActions hasBackButton>
          <Button type="submit" disabled={!form?.syncFormValid}>
            {t("next")}
          </Button>
        </FormActions>
      </FormStep>
    </StyledStep3>
  );
};

export default Step3;

import {
  Button,
  RadioGroup,
  useCallbackRef,
} from "@faxi/web-component-library";
import { FormField, FormRef, useFormRefValues } from "@faxi/web-form";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  APP_URI,
  DEPENDENT_FIELDS,
  FormSteps,
  MaritalStatus,
  StorageKeys,
} from "common";
import { FormActions, FormStep, RequiredLabel } from "components";
import { useAnalytics } from "providers";
import { storageService } from "services";
import { clearFormFields } from "utils";

import { StyledStepPage } from "./Step2.styled";

type FormValue = {
  maritalStatus: MaritalStatus;
};

const onLivingTogetherChange = (livingTogether: string) => {
  const withoutChildren = [
    MaritalStatus.SINGLE,
    MaritalStatus.LIVING_TOGETHER,
  ].includes(livingTogether as MaritalStatus);

  if (withoutChildren) clearFormFields(DEPENDENT_FIELDS.maritalStatus);
};

const Step2: FC = () => {
  const [form, formRef] = useCallbackRef<FormRef>();
  const { t } = useTranslation();

  const history = useHistory();
  const analytics = useAnalytics();

  const handleTrackEvent = useCallback(() => {
    analytics.track("Next Step", {
      stepCompleted: history.location.pathname,
    });
  }, [history.location?.pathname, analytics]);

  const handleSubmit = useCallback(
    async (data: FormValue) => {
      const { maritalStatus } = data;
      storageService.setItem(
        StorageKeys.COMPLETED_STEPS_STORAGE_KEY,
        FormSteps.STEP_2,
      );

      const shouldGoOnNextStep = [
        MaritalStatus.SINGLE,
        MaritalStatus.LIVING_TOGETHER,
      ].includes(maritalStatus);

      handleTrackEvent();

      if (shouldGoOnNextStep) history.push(APP_URI.STEP_3);
      else history.push(APP_URI.STEP_2_SINGLE_WITH_CHILDREN);
    },
    [history, handleTrackEvent],
  );

  const stepFormValues = useFormRefValues(form, "maritalStatus");

  const options = useMemo(
    () => [
      { value: MaritalStatus.SINGLE, label: t("materialstatus_answer_single") },
      {
        value: MaritalStatus.SINGLE_WITH_CHILDREN,
        label: t("materialstatus_answer_singlewchildren"),
      },
      {
        value: MaritalStatus.LIVING_TOGETHER,
        label: t("materialstatus_answer_together"),
      },
      {
        value: MaritalStatus.LIVING_TOGETHER_WITH_CHILDREN,
        label: t("materialstatus_answer_togetherwchildren"),
      },
    ],
    [t],
  );

  return (
    <StyledStepPage>
      <FormStep ref={formRef} onSubmit={handleSubmit}>
        <strong>{t("materialstatus_heading")}</strong>
        <FormField
          name="maritalStatus"
          options={options}
          component={RadioGroup}
          onChange={onLivingTogetherChange}
          orientation="column"
          label={
            <RequiredLabel>
              {t("materiastatus_personalsituation")}
            </RequiredLabel>
          }
        />

        <FormActions hasBackButton>
          <Button type="submit" disabled={!stepFormValues?.maritalStatus}>
            {t("next")}
          </Button>
        </FormActions>
      </FormStep>
    </StyledStepPage>
  );
};

export default Step2;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
function updateURLSearchParamsWithObject(
  usp: URLSearchParams,
  params: Record<string, any>,
) {
  Object.entries(params).forEach(([key, value]) =>
    [undefined, null].includes(value) ? usp.delete(key) : usp.set(key, value),
  );
}
const getParamsObj = (urlParams: URLSearchParams) => {
  const res: { [key: string]: string } = {};
  urlParams.forEach((value, key) => (res[key] = value));
  return res;
};
const generateSearch = (urlParams: URLSearchParams) =>
  `?${urlParams.toString()}`;
export default function useQParamsActions<
  Params extends { [K in keyof Params]?: string } = Record<string, string>,
>() {
  const { search, state } = useLocation();
  const history = useHistory();
  const getUrlSearchParams = useCallback(
    () => new URLSearchParams(window.location.search),
    [],
  );
  const paramsObj = useMemo(
    () => getParamsObj(new URLSearchParams(search)) as Params,
    [search],
  );
  const setQueryParam = useCallback(
    (key: keyof Params, value: string | number | string[], replace = false) => {
      const urlSP = getUrlSearchParams();
      if (urlSP.get(key as string) === value.toString()) return;
      urlSP.set(key as string, value.toString());
      history[replace ? "replace" : "push"]({
        search: generateSearch(urlSP),
        state,
      });
    },
    [getUrlSearchParams, history, state],
  );
  const setMultipleQueryParams = useCallback(
    (
      params: Partial<Record<keyof Params, string | number | null>>,
      replace = false,
    ) => {
      const urlSP = getUrlSearchParams();
      updateURLSearchParamsWithObject(urlSP, params);
      history[replace ? "replace" : "push"]({
        search: generateSearch(urlSP),
        state,
      });
    },
    [history, getUrlSearchParams, state],
  );
  const removeQueryParam = useCallback(
    (key: keyof Params, replace = false) => {
      const urlSP = getUrlSearchParams();
      urlSP.delete(key as string);
      history[replace ? "replace" : "push"]({
        search: generateSearch(urlSP),
        state,
      });
    },
    [history, getUrlSearchParams, state],
  );
  const removeSeveralQueryParams = useCallback(
    (keys: (keyof Params)[], replace = false) => {
      const urlSP = getUrlSearchParams();
      Object.keys(paramsObj).forEach((key) => {
        if (keys.includes(key as keyof Params)) {
          urlSP.delete(key as string);
          history[replace ? "replace" : "push"]({
            search: generateSearch(urlSP),
            state,
          });
        }
      });
    },
    [getUrlSearchParams, history, paramsObj, state],
  );
  return {
    params: paramsObj,
    setQueryParam,
    removeQueryParam,
    setMultipleQueryParams,
    removeSeveralQueryParams,
  };
}

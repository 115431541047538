import { Button } from "@faxi/web-component-library";
import React, { ReactNode } from "react";
import { useHistory } from "react-router";

import { useTranslation } from "react-i18next";
import { StyledFormActions } from "./FormActions.styled";

export type FormActionsProps = {
  hasBackButton?: boolean;
  children?: ReactNode;
};

const FormActions: React.FC<FormActionsProps> = ({
  children,
  hasBackButton,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <StyledFormActions>
      <div>
        {hasBackButton && (
          <Button
            variant="outline"
            type="button"
            onClick={() => history.goBack()}
          >
            {t("Back")}
          </Button>
        )}
        {children}
      </div>
    </StyledFormActions>
  );
};

export default FormActions;
